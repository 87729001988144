import circle from '../../../assets/circle.png';
import group from '../../../assets/group.png';
import group2 from '../../../assets/group2.png';
import line from '../../../assets/line.png';
import whiteline from '../../../assets/line-white.png';
import { ReactComponent as Squiggle } from '../../../assets/squiggle.svg';
import electronics from '../../../assets/electronics.png';
import data from '../../../assets/data-science.png';
import ml from '../../../assets/machine-learning.png';
import web3 from '../../../assets/web3.png';
import webdev from '../../../assets/webdev.png';
import harvard from '../../../assets/Harvard Logo.png';
import iLab from '../../../assets/iLab Logo.png';
import xuanthe from '../../../assets/xuanthe.png';
import ButtonStyled from '../../components/ButtonStyled';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import TopBar from '../../components/TopBar';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getOrgs } from '../../../lib/referrals';

function MainPage() {
  let navigate = useNavigate();
  return (
    <section
      className="bg-blue-dark font-semibold h-screen relative"
      style={{ backgroundImage: `url(${line})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: "cover",
               width: '100vw',
               height: '100vh',
               backgroundPosition: 'center',
              }}
      background-size="contain"
    >
      <div className="bg-line bg-right bg-cover md:bg-contain bg-no-repeat h-screen flex flex-col items-center justify-center">
        <div className="absolute md:bottom-28 md:left-10 text-white text-center md:text-left">
          <h1 className="text-5xl md:text-8xl font-semibold">
            <Link to="/feed">Quidio.</Link>
          </h1>

          <p className="text-xl md:text-3xl mt-5 md:mt-10">
            The {'<'} Programmer {'/ >'}
          </p>
          <p className="text-xl md:text-3xl">Knowledge Marketplace</p>
          <br></br>

          <button
            className="cursor-pointer px-10 py-2 text-xl bg-yellow hover:bg-yellow-light transition whitespace-nowrap text-black font-bold mx-0 rounded-lg mt-1"
            onClick={() => {
              navigate('/Feed');
            }}
          >
            Enter Quidio
          </button>
        </div>
      </div>
    </section>
  );
}

const questlist = [
  {
    id: 1,
    title: '24/7 Support',
    info: 'Have a question? Get in contact with a Quidio representative to get support.',
  },
  {
    id: 2,
    title: 'Secure Payments',
    info: 'All payments are handled securely through Stripe, so you’ll never have to worry.',
  },
  {
    id: 3,
    title: 'Get help quickly',
    info: 'Post your question and the answerers will come to you.',
  },
];

function Questionaire() {
  return (
    <section className="py-16">
      <div className="px-10 lg:container lg:mx-auto">
        <div className="grid md:grid-cols-5 gap-16 md:gap-5 lg:gap-16">
          <div className="md:col-span-2">
            <h1 className="text-black font-bold text-2xl sm:text-3xl lg:text-4xl">
              Ask a question.
            </h1>
            <h1 className="text-black font-bold text-2xl sm:text-3xl lg:text-4xl">
              Add a bounty.
            </h1>
            <h1 className="text-black font-bold text-2xl sm:text-3xl lg:text-4xl">
              Get a great answer.
            </h1>

            <div className="mt-12">
              {questlist.map((quest) => (
                <div className="mt-8 flex space-x-5" key={uuidv4()}>
                  <img src={circle} alt="circle" className="h-5 mt-1" />
                  <div>
                    <h1 className="text-xl sm:text-2xl text-black font-bold">
                      {quest.title}
                    </h1>
                    <p className="text-sm sm:text-base font-medium text-blackQ mt-2">
                      {quest.info}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="md:col-span-3">
            <img src={group} alt="people" className="object-fill mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
}

function Questionaire2() {
  return (
    <section className="py-16"
             style={{ backgroundImage: `url(${line})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: "cover",
                width: '100vw',
                backgroundPosition: 'center',}}>
      <div className="px-10 lg:container lg:mx-auto">
        <div className="grid md:grid-cols-5 md:gap-5 lg:gap-16">
          <div className="md:col-span-3">
            <img src={group2} alt="people" className="object-fill mx-auto" />
          </div>

          <div className="md:col-span-2 mx-auto mt-8">
            <h1 className="text-black font-bold text-3xl">
              Freelance your knowledge.
            </h1>
            <br></br>
            <p className="text-base font-medium text-blackQ mt-2">
              Convert your <b>expertise</b> into <b>income</b>. Answer questions
              and earn bounties. It’s that easy!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function Explore() {
  return (
    <section className="py-12 hidden md:block">
      <div className="px-10 lg:container lg:mx-auto">
        <div className="grid grid-cols-5 gap-5 lg:gap-16">
          <div className="col-span-5 mx-auto">
            <Squiggle />
          </div>

          <div className="col-span-5 mx-auto my-4">
            <h1 className="text-5xl font-semibold">
              <Link to="/feed">Explore.</Link>
            </h1>
          </div>

          <Link className="col-span-1 py-4" to="/search?topics=electronics">
                <div className="m-auto">
                  <div className="relative">
                    <img src={electronics} className="object-cover rounded-lg hover:shadow-2xl" />
                    <div className="absolute bottom-2 left-2 text-base text-white font-bold">
                      Electronics
                    </div>
                  </div>
                </div>
          </Link>
          <Link className="col-span-1 py-4" to="/search?topics=data_science">
                <div className="m-auto">
                  <div className="relative">
                    <img src={data} className="object-cover rounded-lg hover:shadow-2xl" />
                    <div className="absolute bottom-2 left-2 text-base text-white font-bold">
                      Data Science
                    </div>
                  </div>
                </div>
          </Link>
          <Link className="col-span-1 py-4" to="/search?topics=maching_learning">
                <div className="m-auto">
                  <div className="relative">
                    <img src={ml} className="object-cover rounded-lg hover:shadow-2xl" />
                    <div className="absolute bottom-2 left-2 text-base text-white font-bold">
                      Machine Learning
                    </div>
                  </div>
                </div>
          </Link>
          <Link className="col-span-1 py-4" to="/search?topics=web3">
                <div className="m-auto">
                  <div className="relative">
                    <img src={web3} className="object-cover rounded-lg hover:shadow-2xl" />
                    <div className="absolute bottom-2 left-2 text-base text-white font-bold">
                      Web3
                    </div>
                  </div>
                </div>
          </Link>
          <Link className="lg:col-span-1 py-4" to="/search?topics=web_dev">
                <div className="m-auto">
                  <div className="relative">
                    <img src={webdev} className="object-cover rounded-lg hover:shadow-2xl" />
                    <div className="absolute bottom-2 left-2 text-base text-white font-bold">
                      Web Dev
                    </div>
                  </div>
                </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

function Testimonial() {
  return (
    <section className="pt-12 pb-16">
      <div className="px-10 lg:container lg:mx-auto">
        <div className="grid md:grid-cols-6 gap-16">
          <div className="md:col-span-3 object-scale-down">
            <img src={xuanthe} alt="xuanthe" className="object-fill mx-auto" />
          </div>

          <div className="md:col-span-3 mx-8 my-auto">
            <h1 className="text-gray-dark font-bold text-sm">
              WHAT OUR USERS SAY
            </h1>
            <br></br>
            <p className="text-black font-bold text-2xl sm:text-3xl lg:text-4xl">
              <span data-color="super">Quidio</span> is a {'<'}game changer{' />'}
            </p>
            <br></br>
            <p className="text-base font-bold italic text-gray-dark mt-2">
              “Quidio was super helpful for me when I had questions about how to
              build out my personal website. I’ll definitely be using Quidio
              again soon.”
            </p>
            <br></br>
            <p> Xuanthe Nguyen </p>
            <p className="text-gray-dark"> Student @ Harvard </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function CreateAcct() {
  let navigate = useNavigate();
  return (
    <section
      className="bg-yellow font-semibold h-screen relative"
      style={{ backgroundImage: `url(${whiteline})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: "cover",
               width: '100vw',
               height: '100vh',
               backgroundPosition: 'center',
              }}
    >
      <div className="bg-line bg-right bg-cover md:bg-contain bg-no-repeat h-screen flex flex-col items-center justify-center">
        <div className="absolute text-white text-center md:text-left w-3/5">
          <h1 className="text-5xl md:text-8xl font-bold text-blue-dark">
            <Link to="/feed">Get a great answer. </Link>
          </h1>
          <br></br>
          <p className="text-blue-dark text-xl">
            Sign up to be part of the world’s knowledge marketplace and
            show Mom that learning to code actually does pay.
          </p>
          <br></br>
          <ButtonStyled
            className="cursor-pointer px-10"
            title="Sign Up"
            onClick={() => {
              navigate('/sign-up');
            }}
          />
        </div>
      </div>
    </section>
  );
}

function Support() {
  return (
    <div className="w-full bg-gray-light">
      <div className="py-8 flex">
        <div className="lg:w-1/5 w-0"></div>
        <div className="lg:w-3/5 w-full flex justify-between">
          <div className="mx-4 my-auto">
            <p className="text-xl text-gray-dark font-medium">Supported by </p>
          </div>
          <div className="w-24 h-1 bg-gray-dark my-auto hidden md:block"></div>
          <div className="mx-8">
            <img
              src={harvard}
              alt=""
              className="filter grayscale w-48 opacity-80"
            />
          </div>
          <div className="mx-8">
            <img
              src={iLab}
              alt=""
              className="filter grayscale w-48 opacity-80"
            />
          </div>
        </div>
        <div className="lg:w-1/5 w-0"></div>
      </div>
    </div>
  );
}
function HomePage() {
  let params = useParams();
  useEffect(() => {
    // what does .includes do in javascript
    if(params.partner){
      const org = getOrgs(params.partner.toLowerCase())
      org.then((refer) => {
        console.log(refer)
        if(refer){
          localStorage.setItem('partner_org', refer.value);
        }
      })
    }
  }, [params.partner]);
  return (
    <>
      <TopBar />
      <MainPage />
      <Support />
      <Questionaire />
      <Questionaire2 />
      <Explore />
      <Testimonial />
      <CreateAcct />
    </>
  );
}

export default HomePage;
