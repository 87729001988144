import { getAuth } from '@firebase/auth';
import {
  collection,
  doc,
  endBefore,
  getDoc,
  getDocs,
  getFirestore, limit, limitToLast, onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  startAfter,
  where,
} from '@firebase/firestore';
import marked from 'marked';
import sanitizeHtml from 'sanitize-html';
//import config from '../config.js';
import devConfig from '../config/config.dev.json';
import prodConfig from '../config/config.prod.json';
import { QuestionType } from '../firestoreSchema';
const project = process.env.REACT_APP_FIREBASE_PROJECT;
const config = project === 'dev' ? devConfig : prodConfig

type status = "answered" | "unanswered";

export interface questionsOptionsType {
  start?: QuestionType,
  end?: QuestionType,
  limit?: number,
  status?: status[],
  currUser?: any,
  tags?: string[],
  topics?: string[],
  sort?: 'relevance' | 'recency' | 'bounty',
  paginate?: boolean
}

export function listenForQuestions(options: questionsOptionsType, callback: (param: QuestionType[]) => void) {
  let filters = [] as QueryConstraint[];

  // loop through statuses
  if(options.status) {
    for(let i = 0; i < options.status.length; i++) {
      if(options.status[i] === 'answered') {
        filters.push(where('answerAccepted', '==', true));
      } else if(options.status[i] === 'unanswered') {
        filters.push(where('answerAccepted', '==', false));
      }
    }
  }

  if (options.sort) {
    if(options.sort === 'relevance') {
      filters.push(
        orderBy('viewCount', 'desc'),
        orderBy('bounty', 'desc'),
      );
    } else if(options.sort === 'recency') {
      filters.push(
        orderBy('postedTimestamp', 'desc'),
      );
    } else if(options.sort === 'bounty') {
      filters.push(
        orderBy('bounty', 'desc'),
        orderBy('postedTimestamp', 'desc'),
      );
    } else {
      // Force push filters so that pagination works
      filters.push(
        orderBy('viewCount', 'desc'),
        orderBy('bounty', 'desc'),
      );
    }
  }

  if (options.currUser) {
    filters.push(where('asker', '==', options.currUser))
  }

  if (options.tags && options.tags?.length) {
    filters.splice(0, 0, where('tags', 'array-contains-any', options.tags));
  }

  if (options.topics && options.topics?.length) {
    // HACKY WORKAROUND BECAUSE IF YOU TRY TO FILTER BY TAGS & TOPICS:
    // "Uncaught FirebaseError: Invalid query. You cannot use 'array-contains-any' filters with 'in' filters.""
    if(!(options.tags && options.tags?.length)){
      filters.splice(0, 0, where('topic', 'in', options.topics))
    }
  }

  if (options.start) {
    onSnapshot(doc(collection(getFirestore(), 'questions'), options.start.id),
      (docSnap) => {
          filters.push(endBefore(docSnap))
          if (options.limit) {
            filters.push(limitToLast(options.limit))
          }

          onSnapshot(
            query(collection(getFirestore(), 'questions'), ...filters),
            (snapshot) => {
              console.log(filters);
              callback(snapshot.docs.map((doc) => doc.data() as QuestionType));
            }
          );
        }
    )
  } else if (options.end) {
    onSnapshot(doc(collection(getFirestore(), 'questions'), options.end.id),
      (docSnap) => {
        console.log(docSnap);
        filters.push(startAfter(docSnap));
        if (options.limit) {
          filters.push(limit(options.limit))
        }

        onSnapshot(
          query(collection(getFirestore(), 'questions'), ...filters),
          (snapshot) => {
            console.log(filters);
            callback(snapshot.docs.map((doc) => doc.data() as QuestionType));
          }
        );
      }
    )
  } else {
    if (options.limit) {
      filters.push(limit(options.limit))
    }

    onSnapshot(
      query(collection(getFirestore(), 'questions'), ...filters),
      (snapshot) => {
        console.log(filters);
        callback(snapshot.docs.map((doc) => doc.data() as QuestionType));
      }
    );
  }
}


export function listenForQuestionsPaginate(options: any, callback: any) {
  return { error: false };
}


export async function getQuestion(id: any): Promise<any> {
  let document = await getDoc(doc(getFirestore(), 'questions', id));
  return document.data();
}

export async function isNewUser() {
  const q = query(collection(getFirestore(), "questions"), where("asker", "==", getAuth().currentUser?.uid));
  const querySnapshot = await getDocs(q);
  let firstTime = true;
  querySnapshot.forEach((doc) => {
    firstTime = false;
  });
  return firstTime;
}

export async function tryPayment(payment: any, minBounty: any) {
  let currUser = getAuth().currentUser?.uid;
  if(!currUser) {
    return null;
  }
  let document = await getDoc(doc(getFirestore(), 'users-hidden', currUser));
  if (!document.data()) {
    return { type: "insufficientFunds", error: true }
  }

  let reward = document.data()?.reward;
  reward = reward ? reward : 0;
  let balance = document.data()?.balance;
  balance = balance ? balance : 0;

  let tot_bal = balance + reward;

  if (typeof payment !== 'number' || isNaN(payment)) {
    return { type: "invalidPayment", error: true }
  } else if (tot_bal < payment) {
    return { type: "insufficientFunds", error: true }
  } else if (payment < minBounty) {
    return { type: "minimumBounty", error: true }
  }
  return { error: false };
}

export async function submitQuestion(payment: any, title: any, topic: any, bodySource: any, tagsString: any) {
  let response;
  let idToken = await getAuth().currentUser?.getIdToken();
  response = await fetch(config.apiUrl + '/api/add_question', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      payment,
      title,
      topic,
      bodySource,
      tagsString,
    }),
  });

  let body = await response.json();
  return body;
}

export async function removeQuestion(qid: any) {
  let response;
  let idToken = await getAuth().currentUser?.getIdToken();
  response = await fetch(config.apiUrl + '/api/remove_question', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      qid,
      idToken,
    }),
  });

  let body = await response.json();
  return body;
}

export async function editQuestion(qid: any, payment: any, title: any, topic: any, bodySource: any, tagsString: any) {
  let response;
  let idToken = await getAuth().currentUser?.getIdToken();
  response = await fetch(config.apiUrl + '/api/edit_question', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      qid,
      idToken,
      topic,
      payment,
      title,
      bodySource,
      tagsString,
    }),
  });

  let body = await response.json();
  return body;
}

export async function incrementView(qid: any) {
  let response;
  response = await fetch(config.apiUrl + '/api/increment_view', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      qid
    }),
  });

  let body = await response.json();
  return body;
}

export function renderAndSanitizeBody(body: any, allowFormatting: boolean) {
  // @ts-ignore
  return sanitizeHtml(marked(body), {
    allowedTags: (allowFormatting || allowFormatting === undefined)
      ? sanitizeHtml.defaults.allowedTags.concat(['img'])
      : [],
  })
}
