import hljs from 'highlight.js';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsFillCheckSquareFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { markAnswer } from '../../lib/answers';
import { renderAndSanitizeBody } from '../../lib/questions';
import { getUserPublic } from '../../lib/users';
import style from '../../styles/pages/authed/Question.module.css';
import ButtonStyled from './ButtonStyled';
import ProfilePhoto from './ProfilePhoto';
import { colorConfig } from '../../config/color.config';

function Answer(props) {
  let [answererInfo, setAnswererInfo] = useState();
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  useEffect(() => {
    hljs.highlightAll();
  }, [answererInfo]);

  useEffect(() => {
    getUserPublic(props.answer.answerer).then(setAnswererInfo);
  }, [props.answer.answerer]);

  if (!answererInfo) {
    return <p>Loading...</p>;
  }

  return (
    <div className="bg-white rounded-md mb-2 mx-1 shadow-md">
      <div className="px-4 py-4">
        <div className="flex align-center">
          <ProfilePhoto size="small" user={answererInfo} edit={false} />
          <div className="ml-2 inline-block mb-4">
            <p className="text-lg leading-tight font-bold text-black">
              <Link className="hover:underline" to={"/user/".concat(answererInfo.uid)}>{answererInfo.name}</Link>
            </p>
            <p className="text-lg leading-tight text-gray-dark">
              <Link className="hover:underline" to={"/user/".concat(answererInfo.uid)}>{answererInfo.bio}</Link>
            </p>
          </div>
        </div>
        <p className="text-2xl font-semibold my-1 flex justify-between">
          <span>{props.answer.title}</span>
          {props.answer.state === 'live-accepted' ? (
            <IconContext.Provider
              value={{ color: colorConfig['blue-dark'], className: 'h-8 w-8' }}
            >
              <BsFillCheckSquareFill />
            </IconContext.Provider>
          ) : null}
        </p>
        {props.answer.bodySource ? (
          <p
            className={`text-lg my-1 ${style.renderedBody} ${style.richText}`}
            dangerouslySetInnerHTML={{
              __html: renderAndSanitizeBody(props.answer.bodySource, true),
            }}
          />
        ) : (
          <>
            <p>{props.answer.previewText}</p>
            <p className="text-black">
              (The rest of this answer is hidden.)
            </p>
          </>
        )}
        <p className="my-2 text-gray-600">
          Answered by{' '}
          <Link
            to={'/user/' + props.answer.answerer}
            className="hover:underline"
          >
            {answererInfo.name}
          </Link>
          ,{' '}
          {new Date(props.answer.answeredTimestampMilliseconds).toLocaleString(
            [],
            options
          )}
        </p>
        {props.answer.state === 'rejected' ? (
          <p>
            Rejected by asker: “{props.answer.reason || '(no reason given)'}”
          </p>
        ) : null}
        {props.isCurrentUserQuestion &&
          props.answer.state === 'live' &&
          !props.acceptedAnswer.acceptedExists ? (
          <div>
            <ButtonStyled
              title="Accept"
              custom={true}
              className="bg-green-500 hover:bg-green-700 text-white"
              onClick={() => {
                markAnswer(
                  props.questionId,
                  props.answer.id,
                  'live-accepted'
                ).then((result) => {
                  if (result.error) {
                    alert(result.error);
                  } else {
                    window.location.reload();
                  }
                });
              }}
            />
            <ButtonStyled
              title="Reject"
              custom={true}
              className="bg-red-500 hover:bg-red-700 text-white"
              onClick={() => {
                let reason = prompt('Why are you rejecting this answer?');

                if (!reason) {
                  return;
                }

                markAnswer(
                  props.questionId,
                  props.answer.id,
                  'rejected',
                  reason
                ).then((result) => {
                  if (result.error) {
                    alert(result.error);
                  } else {
                    window.location.reload();
                  }
                });
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Answer;
