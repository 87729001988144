import React, { useEffect, useState, useCallback } from 'react';
import { IconContext } from 'react-icons';
import { MdOutlineLocalFireDepartment } from 'react-icons/md';
import { FaCommentAlt, FaTrophy } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { listenForQuestions, questionsOptionsType } from '../../lib/questions';
import { getUserPublic } from '../../lib/users';
import '../../styles/components/react-tabs.css';
import ProfilePhoto from './ProfilePhoto';
import { colorConfig } from '../../config/color.config';
import { QuestionType, UserType } from '../../firestoreSchema';
import ReactTooltip from 'react-tooltip';
import { getTopicOptions } from './ValidateForm'

/*
interface QuestionsListType {
  tags?: string[];
  topics?: string[];
  limit?: number;
  curate: questionsOptionsType['filter'];
}
*/

function QuestionsList(props: questionsOptionsType & { metadataSetter?: any }) {
  const [questions, setQuestions] = useState<undefined | QuestionType[]>();
  const [questionProps, setQuestionProps] = useState<questionsOptionsType>(props);
  const [pageNum, setPageNum] = useState<number>(1);
  const [tempDisableBack, setTempDisableBack] = useState<boolean>(false);
  const [tempDisableForward, setTempDisableForward] = useState<boolean>(false);
  const [moreQuestions, setMoreQuestions] = useState<boolean>(true);
  useEffect(() => {
    console.log("preventing movement until page loaded")
    setTempDisableBack(true)
    setTempDisableForward(true)
    console.log("listening for questions");
    listenForQuestions(
      {
        ...(questionProps.currUser && { currUser: questionProps.currUser }),
        ...(questionProps.tags && { tags: questionProps.tags }),
        ...(questionProps.limit && { limit: questionProps.limit }),
        ...(questionProps.topics && { topics: questionProps.topics }),
        ...(questionProps.status && { status: questionProps.status }),
        ...(questionProps.sort && { sort: questionProps.sort }),
        ...(questionProps.start && { start: questionProps.start }),
        ...(questionProps.end && { end: questionProps.end }),
      },
      (returnedQs: undefined | QuestionType[]) => {
        setQuestions(returnedQs);
        if (props.metadataSetter) {
          props.metadataSetter({
            numQuestionsReturned: returnedQs ? returnedQs.length : 0,
          });
        }

        console.log("listening for questions after last element");
        if(returnedQs == undefined || (returnedQs.length !== questionProps.limit)) {
          console.log('false');
          setMoreQuestions(false);
          setTempDisableBack(false);
          setTempDisableForward(false);
        }
        else {
          listenForQuestions(
            {
              ...(questionProps.currUser && { currUser: questionProps.currUser }),
              ...(questionProps.tags && { tags: questionProps.tags }),
              ...(questionProps.limit && { limit: questionProps.limit }),
              ...(questionProps.topics && { topics: questionProps.topics }),
              ...(questionProps.status && { status: questionProps.status }),
              ...(questionProps.sort && { sort: questionProps.sort }),
              ...(returnedQs[returnedQs.length - 1] && { end: returnedQs[returnedQs.length - 1]}),
            },
            (returnedQs: undefined | QuestionType[]) => {
              if(returnedQs == undefined || returnedQs.length === 0) {
                console.log('false');
                setMoreQuestions(false);
                setTempDisableBack(false);
                setTempDisableForward(false);
              }
              else {
                console.log('true');
                setMoreQuestions(true);
                setTempDisableBack(false);
                setTempDisableForward(false);
              }
            }
          )
        }
      }
    );
  }, [props, questionProps, questionProps.status, questionProps.sort, questionProps.start, questionProps.end]);

  return (
    <div>
      <div className="flex flex-wrap overflow-hidden bg-blue-dark">
        <div className="p-4 w-full">
          {questions === undefined ? (
            <p></p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              {questions.map((question) => (
                <QuestionPreview
                  question={question}
                  key={question.id}
                  allowMarkdown={false}
                />
              ))}
            </div>
          )}
          {questions !== undefined && questions.length === 0 ? (
            <p className="text-white font-medium text-lg p-6">
              No questions found.
            </p>
          ) : null}

          {questionProps.paginate ? (
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px mx-8 my-4" aria-label="Pagination">
              <button className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:text-gray-200 hover:disabled:bg-white disabled:cursor-not-allowed"
                disabled={pageNum === 1}
                onClick={() => {
                  if(questions != null && questions.length > 0 && pageNum > 1 && !tempDisableBack) {
                    let backProps = structuredClone(questionProps);
                    backProps.start = questions[0];
                    // console.log(questions)
                    // console.log(backProps.start)
                    // console.log("BACKWARDS")
                    backProps.end = undefined;
                    setQuestionProps(backProps);
                    setPageNum(pageNum - 1);
                    window.scrollTo(0, 0);
                  }
                }}>
              <span className="sr-only">Previous</span>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </button>
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> {pageNum} </span>
            <button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:text-gray-200 hover:disabled:bg-white disabled:cursor-not-allowed"
                disabled={!moreQuestions}
                onClick={() => {
                  if(questions != null && questions.length === questionProps.limit && !tempDisableForward) {
                    let forwardProps = structuredClone(questionProps);
                    forwardProps.start = undefined;
                    forwardProps.end = questions[questions.length - 1];
                    setQuestionProps(forwardProps);
                    // console.log("Forward!");
                    // console.log(questions);
                    setPageNum(pageNum + 1);
                    window.scrollTo(0, 0);
                  }
                }}>
              <span className="sr-only">Next</span>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </button>
          </nav>
        ) : null}
        </div>
      </div>
      <span>
        {/* PAGINATION DON'T DELETE
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a href="#" className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
          <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing
              <span className="font-medium">1</span>
              to
              <span className="font-medium">10</span>
              of
              <span className="font-medium">97</span>
              results
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a href="#" className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Previous</span>
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="#" aria-current="page" className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 1 </a>
              <a href="#" className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 2 </a>
              <a href="#" className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 3 </a>
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> ... </span>
              <a href="#" className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 8 </a>
              <a href="#" className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 9 </a>
              <a href="#" className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 10 </a>
              <a href="#" className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Next</span>
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </a>
            </nav>
          </div>
        </div>
      </div>
      */}
      </span>
    </div>
  );
}

interface QuestionMicroPreviewType {
  category: string;
  question: QuestionType;
}

export function QuestionMicroPreview(props: QuestionMicroPreviewType) {
  let color;
  if (props.category === 'bounty') {
    color = 'text-blue-dark';
  } else if (props.category === 'hot') {
    if (props.question.answerAccepted) {
      color = 'text-blue-dark';
    } else {
      color = 'text-black';
    }
  }

  return (
    <div className="mt-1">
      <span className="font-normal hover:underline text-md my-1 flex justify-between">
        <span className="no-underline hover:underline">
          <div
            className={`px-2 align-top inline-block whitespace-normal ${color}`}
          >
            {props.category === 'bounty' && (
              <p>${props.question.bounty / 100}</p>
            )}
            {props.category === 'hot' && (
              <p>{props.question.liveAnswersCount}</p>
            )}
          </div>
          <div className="align-top inline-block whitespace-normal">
            <Link to={`/q/${props.question.id}`} className="hover:underline">
              {props.question.title || 'Untitled'}
            </Link>
          </div>
        </span>
      </span>
    </div>
  );
}

interface BadgeType {
  icon: any;
  color: 'orange' | 'yellow' | 'green' | 'blue';
  margin: string;
  tooltip: string;
  size: string;
}

function Badge(props: BadgeType) {
  return (
    <div
      data-tip={props.tooltip}
      className="bg-blue-dark w-6 h-6 rounded-full inline-block mx-1"
    >
      <div className="w-full h-full">
        <IconContext.Provider
          value={{
            color: colorConfig[props.color],
            className: 'block mx-auto ' + props.margin + ' ' + props.size,
          }}
        >
          {props.icon}
        </IconContext.Provider>
      </div>
    </div>
  );
}

interface QuestionPreviewType {
  question: QuestionType;
  key: any;
  allowMarkdown: boolean;
}

export function QuestionPreview(props: QuestionPreviewType) {
  let [askerInfo, setAskerInfo] = useState<UserType | undefined>();
  const isHot = props.question.viewCount > 50;
  //const isTopAsker = Math.random() > 0.5;
  const isBigBounty = props.question.bounty > 1000;

  useEffect(() => {
    getUserPublic(props.question.asker).then(setAskerInfo);
  }, [props.question.asker]);

  let bountyDollars = props.question.bounty / 100;
  let bountyWhole = Math.floor(bountyDollars);
  let cents = Math.floor((bountyDollars - bountyWhole) * 100)
    .toString()
    .padStart(2, '0');

  return (
    <div className="bg-white rounded-xl mb-8 mx-4 text-black">
      <div className="px-4 py-4 mb-8 h-32 break-words">
        <p className="text-2xl font-semibold my-1">
          <span className="text-2xl">
            ${bountyWhole}.{cents}
          </span>
        </p>
        <p className="text-lg font-medium line-clamp-2">
          <span className="no-underline hover:underline">
            <Link to={`/q/${props.question.id}`}>
              {props.question.title || 'Untitled'}
            </Link>
          </span>
        </p>
        <p>
          <span className="text-gray flex justify-between mt-2">
            <span>
              {timeDifference(
                new Date(),
                props.question.postedTimestamp.toDate()
              )}
            </span>
            {props.question.topic && <span className="font-bold">{getTopicOptions(props.question.topic)[0].label}</span>}
          </span>
        </p>
      </div>
      <div className="bg-blue-dark h-1 w-full"></div>
      <div className="px-2 py-4">
        <div className="rounded-md flex justify-between">
          {props.question.answerAccepted ? (
            <span className="inline-block leading-snug my-0.5 btn text-black font-semibold border-2 border-yellow bg-yellow px-2 rounded-xl mx-1">
              <Link to={`/q/${props.question.id}`}>Solved</Link>
            </span>
          ) : (
            <span className="inline-block leading-snug my-0.5 btn whitespace-nowrap border-2 font-semibold border-yellow bg-white px-2 rounded-xl mx-1">
              <IconContext.Provider
                value={{ color: 'black', className: 'inline mr-1 mb-0.5' }}
              >
                <FaCommentAlt />
              </IconContext.Provider>
              <Link to={`/q/${props.question.id}`}>
                {props.question.liveAnswersCount +
                  props.question.rejectedAnswersCount || 0}{' '}
                answer
                {props.question.liveAnswersCount +
                  props.question.rejectedAnswersCount ===
                1
                  ? ''
                  : 's'}
              </Link>
            </span>
          )}
          <div>
            <ReactTooltip />
            <ProfilePhoto
              className="mx-2"
              size="xs"
              user={askerInfo}
              edit={false}
            />
            {isHot && (
              <Badge
                icon={<MdOutlineLocalFireDepartment />}
                color="orange"
                margin="mt-0.5"
                tooltip="This question is hot"
                size="w-5 h-5"
              />
            )}
            {/*
            {isTopAsker && (
              <Badge
                icon={<BsFillHandThumbsUpFill />}
                color="yellow"
                margin="mt-1"
                tooltip="This question was asked by a top asker"
              />
            )}
            */}
            {isBigBounty && (
              <Badge
                icon={<FaTrophy />}
                color="green"
                margin="mt-1"
                size="w-4 h-4"
                tooltip="This question has a large bounty"
              />
            )}
          </div>
          {/*
          {props.question.tags &&
            props.question.tags
              .slice(0, 3)
              .map((tag: string) => <Tag key={uuidv4()} name={tag} />)}
          */}
        </div>
      </div>
    </div>
  );
}

function timeDifference(current: Date, previous: Date) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current.getTime() - previous.getTime();
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago';
  }
}

export default QuestionsList;
