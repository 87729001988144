import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { useState } from 'react';
import caine from '../../../assets/caine_headshot.png';
import eva from '../../../assets/eva_headshot.png';
import kelsey from '../../../assets/kelsey_headshot.png';
import { IconContext } from 'react-icons';
import { colorConfig } from '../../../config/color.config';
import { MdOutlineLocalFireDepartment } from 'react-icons/md';
import { VscBook } from 'react-icons/vsc';
import { BsPeopleFill } from 'react-icons/bs';

function Header() {
  return (
    <section className="text-black flex flex-col items-center bg-blue-dark pb-10">
      <div className="mt-20 mx-10 text-white">
        <h1 className="text-center text-4xl font-bold">
          <IconContext.Provider
            value={{
              color: colorConfig['yellow'],
              className: 'h-16 w-16 inline mx-2',
            }}
          >
            <MdOutlineLocalFireDepartment />
          </IconContext.Provider>
          <span className="mx-2">
            Our Team <br /> <br />{' '}
          </span>
        </h1>
      </div>
    </section>
  );
}

function OurStory() {
  return (
    <section className="text-black flex flex-col items-center bg-offwhite">
      <div className="grid md:grid-cols-5 mx-40">
        {/* Section 1 */}
        <div className="md:col-span-2 py-24">
          <div className="bg-blue-dark w-24 h-24 rounded-full inline-block mx-1">
            <div className="w-full h-full">
              <IconContext.Provider
                value={{
                  color: colorConfig['yellow'],
                  className: 'w-16 h-16 block mx-auto my-4',
                }}
              >
                <VscBook />
              </IconContext.Provider>
            </div>
          </div>
          <h3 className="text-2xl font-semibold my-3 ml-0">
            The Story <br /> Behind Quidio
          </h3>
        </div>

        <div className="md:col-span-3 pt-16">
          <p className="mt-5 ">
            {' '}
            Quidio was started at Harvard in 2021 after our co-founder, Caine,
            posted a question on a popular code forum and never got an answer.
            Talking with other CS majors, he quickly realized this was a common
            problem.{' '}
          </p>
          <p className="mt-5">
            {' '}
            Enter Quidio: the knowledge marketplace where question askers can
            add bounties to their questions, and answerers can get paid for
            their knowledge.{' '}
          </p>
        </div>

        {/* Section 2 */}
        <div className="md:col-span-3 pt-8">
          <p className="mt-5">
            {' '}
            Today, Quidio is incubated at the Harvard Innovation Labs, run by a
            founding team of three computer science majors who have experienced
            this problem first hand.{' '}
          </p>
        </div>

        <div className="md:col-span-2 pt-8 pl-40">
          <div className="w-full h-24">
            <div className="bg-blue-dark w-24 h-24 rounded-full inline-block mx-1 float-right">
              <div className="w-full h-full">
                <IconContext.Provider
                  value={{
                    color: colorConfig['yellow'],
                    className: 'w-16 h-16 block mx-auto my-4',
                  }}
                >
                  <BsPeopleFill />
                </IconContext.Provider>
              </div>
            </div>
          </div>
          <h3 className="text-2xl font-semibold my-3 pt-4 text-right">Meet Our Team</h3>
        </div>
      </div>
    </section>
  );
}

function Team() {
  return (
    <section className="text-black flex flex-col items-center bg-offwhite pb-10 pt-14">
      <div className="grid md:grid-cols-3 mx-8">
        <div className="md:col-span-1 py-10 pl-20">
          <img
            src={caine}
            alt="people"
            className="w-3/5 mx-auto border border-black rounded-2xl"
          />
          <p className="font-code font-bold text-center mt-5 py-2">
            {' '}
            Caine Ardayfio{' '}
          </p>
          <p className="font-code text-center text-sm">
            {' '}
            Caine is a full-stack founder who builds punch-activated
            flamethrowers. In his free time, he collects AR coins on his
            electric skateboard.{' '}
          </p>
        </div>
        <div className="md:col-span-1 p-10">
          <img
            src={kelsey}
            alt="people"
            className="w-3/5 mx-auto border border-black rounded-2xl"
          />
          <p className="font-code font-bold text-center mt-5 py-2">
            {' '}
            Kelsey Chen{' '}
          </p>
          <p className="font-code text-center text-sm">
            {' '}
            Kelsey is a creative with a love for art and design. She's part of
            the dev team for Datamatch, a college matchmaking service with over
            50k users.{' '}
          </p>
        </div>
        <div className="md:col-span-1 py-10 pr-20">
          <img
            src={eva}
            alt="people"
            className="w-3/5 mx-auto border border-black rounded-2xl"
          />
          <p className="font-code font-bold text-center mt-5 py-2">
            {' '}
            Eva Tuecke{' '}
          </p>
          <p className="font-code text-center text-sm">
            {' '}
            Eva sources startups at Contrary Capital and helps run a startup
            accelerator. When she's not grinding out grant applications, she
            likes to lift heavy things.
          </p>
        </div>
      </div>
    </section>
  );
}

function AboutPage() {
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);
  return (
    <>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded}/>
      <TopBar />
      <div className={`${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <Header />
        <OurStory />
        <Team />
      </div>
    </>
  );
}

export default AboutPage;
