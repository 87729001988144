import { getAuth } from '@firebase/auth';
import { doc, getDoc, getDocs, getFirestore, where, collection, query, orderBy } from '@firebase/firestore';
//import config from '../config.js';
import devConfig from '../config/config.dev.json';
import prodConfig from '../config/config.prod.json';
import { PayLogType } from '../firestoreSchema';

const project = process.env.REACT_APP_FIREBASE_PROJECT;
const config = project === 'dev' ? devConfig : prodConfig

export async function initialMoney(amount: any) {
  let idToken = await getAuth().currentUser?.getIdToken();

  let response = await fetch(config.apiUrl + '/api/initial-money', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      amount,
    }),
  });

  let body = response.json();
  return body;
}

export async function goToAddBalance(amountCents: any) {
  let idToken = await getAuth().currentUser?.getIdToken();
  let url =
    config.apiUrl +
    '/api/add-to-balance?idToken=' +
    idToken +
    '&amount=' +
    amountCents;
  // transport the user to the following url (which is hosted by the server)
  window.location.href = url;
}

export async function goToPaymentLink() {
  let idToken = await getAuth().currentUser?.getIdToken();
  let url = config.apiUrl + '/api/payment-link?idToken=' + idToken;

  window.location.href = url;
}

export async function withdraw(amount: any) {
  let idToken = await getAuth().currentUser?.getIdToken();
  let response = await fetch(config.apiUrl + '/api/withdraw', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      amount,
    }),
  });
  let body = response.json();
  return body;
}

export async function getHiddenData() {
  const currUser = getAuth().currentUser;
  if (!currUser) {
    return {};
  }

  let document = await getDoc(
    doc(getFirestore(), 'users-hidden', currUser.uid)
  );
  return document.data() || {};
}

export async function getPayLogs(type: PayLogType[]) {
  if (!getAuth().currentUser) {
    return {};
  }

  const filters = [where('uid', '==', getAuth().currentUser?.uid)];
  if (Array.isArray(type)) {
    filters.push(where('type', 'in', type));
  } else {
    filters.push(where('type', '==', type));
  }

  filters.push(orderBy('timestamp', 'desc'))
  const my_query = query(collection(getFirestore(), 'pay-logs'), ...filters);
  let querySnapshot = await getDocs(my_query);
  let documents: any[] = [];
  querySnapshot.forEach((doc) => {
    documents.push(doc.data())
  });
  return documents;
}
