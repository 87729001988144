import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserPublic } from '../../lib/users';
import ProfilePhoto from './ProfilePhoto';

function Answer(props) {
  let [commenterInfo, setCommenterInfo] = useState();
  let options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  useEffect(() => {
    getUserPublic(props.comment.commenter).then(setCommenterInfo);
  }, [props.comment.commenter]);

  if (!commenterInfo) {
    return <p>Loading...</p>;
  }
  return (
    <div className="bg-gray-light border-b-2">
      <div className="px-4 py-4">
        <div className="flex align-center">
          <ProfilePhoto size="small" user={commenterInfo} edit={false} />
          <div className="ml-2 inline-block mt-2">
            <p className="text-md leading-tight font-semibold text-black">
              <Link className="hover:underline" to={"/user/".concat(commenterInfo.uid)}>{commenterInfo.name} </Link>
              <span className="font-normal text-gray-600 text-sm">
                {new Date(
                  props.comment.commentedTimestampMilliseconds
                ).toLocaleString([], options)}
              </span>
            </p>
            <p>{props.comment.comment}</p>

          </div>
        </div>
      </div>
    </div>
  );
}


export default Answer;
