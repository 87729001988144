/* eslint-disable jsx-a11y/alt-text */
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import GoogleLogo from '../../../assets/google-g.png';
import { getUserPublic, updateCurrentUser } from '../../../lib/users';
import styledBackground from '../../../styles/components/StyledPageBackground.module.css';
import style from '../../../styles/pages/unauthed/SignUpLogIn.module.css';
import ButtonStyled from '../../components/ButtonStyled';
import InputWithLabel from '../../components/InputWithLabel';
import {
  initialMoney,
} from '../../../lib/payments';
import { getOrgs } from '../../../lib/referrals';


function UnauthedLogInPage(props: {next?: string}) {
  let navigate = useNavigate();
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  return (
    <div className={styledBackground.page}>
      <div className={style.pane}>
        <h2>Log in</h2>
        <button className={style.googleButton} onClick={logInGoogle}>
          <img src={GoogleLogo} className="inline mx-1" /> Sign in with Google
        </button>
        <div className={style.hr} />
        <InputWithLabel
          title="Email"
          inputProps={{
            value: email,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
            className: 'w-full'
          }}
        />
        <InputWithLabel
          title="Password"
          className={style.inputWithLabel}
          inputProps={{
            value: password,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value),
            type: 'password',
            className: 'w-full'
          }}
        />
        <ButtonStyled
          title="Continue"
          onClick={logInEmail}
          size="large"
        />
        <p className={style.alternateDisclaimer}>
          Need an account? <Link to="/sign-up">Sign up.</Link>
        </p>
      </div>
    </div>
  );

  function logInGoogle() {
    let provider = new GoogleAuthProvider();
    signInWithPopup(getAuth(), provider).then((result) => {
      // @ts-ignore: Object is possibly 'null'.
      getUserPublic(getAuth().currentUser.uid).then((val) => {
        const referrer = getOrgs(localStorage.getItem('partner_org') || '');
        const photoURL = "https://picsum.photos/seed/quidio" + result.user.displayName + "/200/200";
        if (!val) { // if the user doesn't have an account associated with them
          referrer.then((refer) => {
            if(refer){
              initialMoney(refer.price);
              return updateCurrentUser({
                name: result.user.displayName,
                referrer: refer.value,
                photoURL
              });
            }
          })
        }
        return updateCurrentUser({
          name: result.user.displayName,
          photoURL
        });
      });
      navigate(props.next || '/feed');
    }).catch(function (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // The email of the user's account used.
      console.log(errorCode, errorMessage, email, credential);
    });
  }

  function logInEmail() {
    signInWithEmailAndPassword(getAuth(), email, password).then((result) => {
      navigate(props.next || '/feed');
    }).catch((error) => {
      alert('Invalid credentials. Please try again.');
    });
  }
}

export default UnauthedLogInPage;
