import { getAuth } from '@firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsFillCheckSquareFill } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router';
import {
  getAnswersFull,
  postAnswer
} from '../../../lib/answers';
import {
  getComments,
  postComment
} from '../../../lib/comments';
import { getQuestion } from '../../../lib/questions';
import '../../../styles/components/code_highlight.min.css';
import Answer from '../../components/Answer';
import ButtonStyled from '../../components/ButtonStyled';
import Comment from '../../components/Comment';
import Editor from '../../components/Editor';
import { InputInline } from '../../components/Input';
import ProfilePhoto from '../../components/ProfilePhoto';
import Question from '../../components/Question';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { colorConfig } from '../../../config/color.config';
import { MdQuestionAnswer } from 'react-icons/md';

function QuestionPage() {
  let params = useParams();
  let [questionData, setQuestionData] = useState();
  let [answers, setAnswers] = useState();
  let [comments, setComments] = useState([]);
  let [newAnswerTitle, setNewAnswerTitle] = useState('');
  let [newComment, setNewComment] = useState('');
  let [isAuthed, setIsAuthed] = useState('');
  let navigate = useNavigate();
  let editorRef = useRef(null);
  let [live, setLive] = useState(null);
  let [rejected, setRejected] = useState(null);
  let [acceptedAnswer, setAccepted] = useState({ acceptedExists: false, isLoading: true });
  let [isCurrentUserQuestion, setCurrentQuestion] = useState(null)


  useEffect(() => {
    if (answers) {
      let acceptedExists = false;
      answers.forEach(function (item, index) {
        if (item.state === 'live-accepted') {
          acceptedExists = true;
        }
      });
      setAccepted({ acceptedExists, isLoading: false })
    }
    setLive((answers || []).filter(
      (answer) => answer.state === 'live' || answer.state === 'live-accepted'
    ));
    setRejected((answers || []).filter(
      (answer) => answer.state === 'rejected'
    ));
  }, [answers])

  useEffect(() => {
    getQuestion(params.id).then((question) => {
      setQuestionData(question);
      getAnswersFull(params.id).then((result) => {
        setAnswers(result.answers || [])
      });
      getComments(params.id).then((result) => {
        setComments(result.comments || [])
      })
    });
  }, [params.id]);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setIsAuthed(true);
      } else {
        setIsAuthed(false);
      }
    });
  }, []);

  useEffect(() => {
    setCurrentQuestion(questionData && questionData.asker === getAuth().currentUser?.uid);
  }, [questionData]);
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);
  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded}/>
      <TopBar />
      <div className={`flex flex-wrap overflow-hidden py-10 bg-offwhite ${(isExpanded && !isMobile) ? "ml-60" : 'ml-24'}`}>
        <div className="lg:w-1/6 md:w-1/6 w-0 overflow-hidden"></div>
        <div className="lg:w-2/3 md:w-2/3 w-full px-2 overflow-hidden">
          {acceptedAnswer.acceptedExists && !acceptedAnswer.isLoading && (
            <div className="bg-white rounded-md mb-4 mx-1 shadow-md">
              <div className="px-4 py-4">
                <p className="text-xl font-medium my-1 flex justify-between">
                  <IconContext.Provider value={{ color: "rgb(220, 58, 29)", className: "h-12 w-12" }}>
                    <BsFillCheckSquareFill />
                  </IconContext.Provider>
                  <span className="ml-4">This question is closed. It has an accepted answer and the bounty has already been rewarded.</span>
                </p>
              </div>
            </div>
          )}
          {!acceptedAnswer.acceptedExists && !acceptedAnswer.isLoading && (
            <div className="bg-white rounded-md mb-4 mx-1 shadow-md">
              <div className="px-4 py-4">
                <p className="text-xl font-medium my-1 flex justify-between">
                  <IconContext.Provider value={{ color: colorConfig['blue-dark'], className: "h-12 w-12" }}>
                    <MdQuestionAnswer />
                  </IconContext.Provider>
                  <span className="ml-4">This question is live and currently accepting answers. The question asker will select an answer to receive the bounty.</span>
                </p>
              </div>
            </div>
          )}
          <div className="relative inline-block w-full">
            <div className="bg-white rounded-md mb-4 mx-1 shadow-md">
              <div className="px-4 py-4">
                {questionData && <Question question={questionData} key={questionData.id} acceptedAnswer={acceptedAnswer} isCurrentUserQuestion={isCurrentUserQuestion} />}
                <div className="flex justify-center bg-gray-light w-full mt-2 py-2 border-2">
                  {isAuthed ? (
                    <>
                      <ProfilePhoto className="ml-2" size="small" edit={false} />
                      <InputInline
                        className="ml-2 w-full my-2"
                        placeholder={"Add a comment..."}
                        onChange={(event) => {
                          setNewComment(event.target.value)
                        }}
                      />
                      <ButtonStyled
                        title="Add comment"
                        className="my-2 mx-2"
                        onClick={() => {
                          postComment(
                            params.id,
                            newComment
                          ).then((result) => {
                            if (result.error) {
                              alert(result.error);
                            } else {
                              window.location.reload();
                            }
                          });
                        }}
                      />
                    </>) : (
                    <>
                      <InputInline
                        className="ml-2 w-full my-2"
                        placeholder={"Add a comment..."}
                        onChange={(event) => {
                          setNewComment(event.target.value)
                        }}
                      />
                      <ButtonStyled
                        title="Login to comment"
                        className="my-2"
                        onClick={() => {
                          navigate('/log-in')
                        }}
                      />
                    </>
                  )}
                </div>
                <div>
                  {comments.map((comment) => (
                    <Comment
                      comment={comment}
                      questionId={params.id}
                      key={comment.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <h2 className="text-xl font-bold mb-2">Proposed Answers</h2>
          {live?.map((answer) => (
            <Answer
              answer={answer}
              isCurrentUserQuestion={isCurrentUserQuestion}
              acceptedAnswer={acceptedAnswer}
              questionId={params.id}
              key={answer.id}
            />
          ))}
          {live?.length === 0 ? (
            <p>No answers yet.</p>
          ) : null}
          {rejected?.length > 0 ? (
            <h2 className="text-xl font-bold mb-2 text-black">Rejected Answers</h2>
          ) : null}
          {rejected?.map((answer) => (
            <Answer
              answer={answer}
              isCurrentUserQuestion={isCurrentUserQuestion}
              acceptedAnswer={acceptedAnswer}
              questionId={params.id}
              key={answer.id}
            />
          ))}
          {!acceptedAnswer.acceptedExists && !acceptedAnswer.isLoading && !isCurrentUserQuestion && (
            <>
              <h2 className="text-lg font-bold mb-2 text-black">Add an answer</h2>
              <div className="bg-white rounded-md p-4 mb-4 mx-1 shadow-md">
                <p>Title</p>
                <InputInline
                  className="w-full my-2"
                  onChange={(event) => {
                    setNewAnswerTitle(event.target.value)
                  }}
                />
                <p>Body</p>
                <Editor ref={editorRef} />
                {isAuthed ? (
                  <ButtonStyled
                    title="Post Answer"
                    size="large"
                    onClick={() => {
                      postAnswer(
                        params.id,
                        newAnswerTitle,
                        editorRef.current.value()
                      ).then((result) => {
                        if (result.error) {
                          alert(result.error);
                        } else {
                          window.location.reload();
                        }
                      });
                    }}
                  />
                ) :
                  <ButtonStyled
                    title="Login to post"
                    size="large"
                    onClick={() => {
                      const win = window.open("/log-in", "_blank");
                      win.focus();
                    }}
                  />
                }
              </div>
            </>
          )}
        </div>
        <div className="lg:w-1/6 md:w-1/6 w-0 overflow-hidden"></div>
      </div>
    </div>
  );
}


export default QuestionPage;
