import React from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { useState } from 'react';

function NotFoundPage() {
    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
    const isMobile = window.innerWidth < 768;

    let defState = true;;
    if(sidebarCollapsed === 'true' || isMobile){
        defState = false;
    }
    else if (sidebarCollapsed === 'false') {
        defState = true;
    }
    const [isExpanded, setExpanded] = useState(defState);
    return (
        <div>
            <SideBar isExpanded={isExpanded} setExpanded={setExpanded}/>
            <TopBar/>
            <div className={`text-center ${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
                <h1 className="text-4xl mt-40 mb-5">Error 404 - Page Not Found.</h1>
                <Link to='/Feed' className="font-semibold mb-40">Navigate Home.</Link>
            </div>
        </div>
    );
}

export default NotFoundPage;
