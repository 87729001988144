import { Input } from './Input';
import ReactTooltip from 'react-tooltip';
import { FaQuestionCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'


function InputWithLabel(props) {
  if (props.toolTip) {
    return (
      <label className={props.className}>
        <ReactTooltip />
        <span data-tip={props.toolTip}>
          {props.title}
          {props.error && <p className="text-red-500">{props.error}</p>}
          <IconContext.Provider value={{ color: "black", className: "inline mb-1 mx-1" }}>
            <FaQuestionCircle />
          </IconContext.Provider>
        </span>
        <Input {...props.inputProps} />
      </label>
    );
  } else {
    return (
      < label className={props.className} >
        {props.title}
        {props.error && <p className="text-red-500">{props.error}</p>}
        < Input {...props.inputProps} />
      </label >
    );
  }
}

export default InputWithLabel;
