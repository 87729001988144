import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import GoogleLogo from '../../../assets/google-g.png';
import { getUserPublic, updateCurrentUser } from '../../../lib/users';
import styledBackground from '../../../styles/components/StyledPageBackground.module.css';
import style from '../../../styles/pages/unauthed/SignUpLogIn.module.css';
import ButtonStyled from '../../components/ButtonStyled';
import InputWithLabel from '../../components/InputWithLabel';
import {
  initialMoney,
} from '../../../lib/payments';
import { getOrgs, isOrg } from '../../../lib/referrals';


function UnauthedSignUpPage() {
  let navigate = useNavigate();
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  const referrer = getOrgs(localStorage.getItem('partner_org') || '');

  return (
    <div className={styledBackground.page}>
      <div className={style.pane}>
        <h2>Sign up</h2>
        <button className={style.googleButton} onClick={logInGoogle}>
          <img src={GoogleLogo} alt="G" className="inline mx-1" /> Sign in with Google
        </button>
        <div className={style.hr} />
        <InputWithLabel
          title="Name"
          inputProps={{
            value: name,
            onChange: (event) => setName(event.target.value),
            className: 'w-full'
          }}
        />
        <InputWithLabel
          title="Email"
          className={style.inputWithLabel}
          inputProps={{
            value: email,
            onChange: (event) => setEmail(event.target.value),
            className: 'w-full'
          }}
        />
        <InputWithLabel
          title="Password"
          className={style.inputWithLabel}
          inputProps={{
            value: password,
            onChange: (event) => setPassword(event.target.value),
            type: 'password',
            className: 'w-full'
          }}
        />
        <ButtonStyled
          title="Continue"
          onClick={logInEmail}
          size="large"
        />
        <p className={style.alternateDisclaimer}>
          Have an account? <Link to="/log-in">Log in.</Link>
        </p>
      </div>
    </div>
  );

  function logInGoogle() {
    let provider = new GoogleAuthProvider();

    signInWithPopup(getAuth(), provider).then((result) => {
      // @ts-ignore: Object is possibly 'null'.
      getUserPublic(getAuth().currentUser.uid).then((val) => {
        const photoURL = "https://picsum.photos/seed/quidio" + result.user.displayName + "/200/200";

        if (!val) { // if the user doesn't have an account associated with them
          //initialMoney(600)
          referrer.then((refer) => {
            if (refer) {
              initialMoney(refer.price);
              return updateCurrentUser({
                name: result.user.displayName,
                referrer: refer.value,
                photoURL
              });
            }
            return updateCurrentUser({
              name: result.user.displayName,
              photoURL
            });
          })
        }
        return updateCurrentUser({
          name: result.user.displayName,
          photoURL
        });
      });
      navigate('/feed');
    }).catch(function (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // The email of the user's account used.
      console.log(errorCode, errorMessage, email, credential);
    })
  }


  function logInEmail() {
    createUserWithEmailAndPassword(getAuth(), email, password)
      .then((result) => {
        if (result.user) {
          const photoURL = "https://picsum.photos/seed/quidio" + (Math.random() + 1).toString(36).substring(7) + "/200/200";
          referrer.then((refer) => {
            if (refer) {
              initialMoney(refer.price);
              return updateCurrentUser({
                name,
                referrer: refer.value,
                photoURL
              });
            }
            return updateCurrentUser({
              name,
              photoURL
            });
          })
        }
      })
      .then(() => {
        navigate('/feed');
      })
      .catch((error) => {
        alert(error);
      });
  }
}

export default UnauthedSignUpPage;
