import { getAuth } from '@firebase/auth';
import {
  collectionGroup,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from '@firebase/firestore';


import devConfig from '../config/config.dev.json';
import prodConfig from '../config/config.prod.json';
import { AnswerType, AnswerMeta } from '../firestoreSchema';

const project = process.env.REACT_APP_FIREBASE_PROJECT;
const config = project === 'dev' ? devConfig : prodConfig

export async function getAnswersFull(questionId: string) {
  let response = await fetch(
    config.apiUrl +
    '/api/answers-full?questionId=' +
    questionId
  );
  let body = await response.json();
  return body;
}

// DEPRECATED
export async function getAnswersPreview(questionId: string) {
  let idToken = await getAuth().currentUser?.getIdToken();

  if (!idToken) {
    idToken = "unauthenticated"
  }

  let response = await fetch(
    config.apiUrl +
    '/api/answers-preview?questionId=' +
    questionId +
    '&idToken=' +
    idToken
  );

  let body = await response.json();
  return body;
}

export async function postAnswer(questionId: string, title: string, bodySource: string) {
  let idToken = await getAuth().currentUser?.getIdToken();
  let response = await fetch(config.apiUrl + '/api/answers', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      questionId,
      title,
      bodySource,
    }),
  });

  let body = await response.json();
  return body;
}

export async function markAnswer(questionId: string, answerId: string, state: string, reason: string) {
  let idToken = await getAuth().currentUser?.getIdToken();
  let response = await fetch(config.apiUrl + '/api/mark-answer', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      questionId,
      answerId,
      state,
      reason,
    }),
  });

  let body = await response.json();
  return body;
}

export async function listenForAnswers(options: {filter: string, currUser?: any}, callback: (param: AnswerMeta[]) => void) {
  let filters;
  switch (options.filter) {
    case 'all':
      filters = [
        orderBy('answeredTimestamp', 'desc'),
      ];
      break;
    default:
      filters = [
        orderBy('answeredTimestamp', 'desc'),
      ];
  }

  if (options.currUser) {
    filters.push(where('answerer', '==', options.currUser))
  }

  onSnapshot(
    query(collectionGroup(getFirestore(), 'answers'), ...filters),
    (snapshot) => {
      callback(snapshot.docs.map((doc) => {
        return { data: (doc.data() as AnswerType), parent: doc.ref.parent.parent?.id as string }
      }));
    }
  );
}