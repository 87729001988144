function ButtonStyled(props) {
  if (props.custom === undefined || props.custom !== true) {
    return (
      <button
        className={`
        ${props.className} bg-blue-dark hover:bg-blue transition whitespace-nowrap text-white font-bold rounded-full py-1.5 px-3 mt-1 inline-flex items-center
        `}
        onClick={props.onClick}
      >
        {props.icon}
        <span>{props.title}</span>
      </button>
    );
  } else {
    return (
      <button
        className={`
        ${props.className} transition whitespace-nowrap font-bold mx-2 rounded-full py-1.5 px-3 mt-1
        `}
        onClick={props.onClick}
      >
        {props.title}
      </button>
    );
  }
}

export default ButtonStyled;
