import { IconContext } from 'react-icons';
import { IoFilter } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';
import { createSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import { useOutsideAlerter } from './useOutsideAlerter';
import style from '../../styles/components/Filter.module.css';
// url_value: this is the name of the value that is added in the url...
// i.e. in http://localhost:3000/search?status=unanswered&topics=general, the value of the status is 'unanswered'
// appearance: the word that actually shows up in the tag
//
const filters = {
  unanswered: {
    url_value: 'unanswered',
    appearance: 'unanswered',
  },
  answered: {
    url_value: 'answered',
    appearance: 'answered',
  },
  general: {
    url_value: 'general',
    appearance: 'general',
  },
  web3: {
    url_value: 'web3',
    appearance: 'web 3',
  },
  machine_learning: {
    url_value: 'machine_learning',
    appearance: 'machine learning',
  },
  web_dev: {
    url_value: 'web_dev',
    appearance: 'web dev',
  },
  relevant: {
    url_value: 'relevance',
    appearance: 'popular',
  },
  bounty: {
    url_value: 'bounty',
    appearance: 'bounty',
  },
  recency: {
    url_value: 'recency',
    appearance: 'recency',
  },
};

interface TagType {
  callback: any;
  index: string;
  currParams: any;
}

function Tag(props: TagType) {
  // @ts-ignore
  const name = filters[props.index]['appearance'];
  // @ts-ignore
  const url_value = filters[props.index]['url_value'];
  const isActive = props.currParams === url_value;
  return (
    <span
      className={
        (isActive ? 'bg-yellow font-gray ' : 'font-gray ') +
        'inline-block leading-snug my-0.5 btn whitespace-nowrap font-semibold border-2 border-yellow px-2 rounded-xl mx-1 hover:bg-yellow cursor-pointer'
      }
    >
      <p onClick={() => {
        props.callback(url_value)
        // NOTE REMOVE THIS AT SOME POINT
        window.location.reload()
      }}>{name}</p>
    </span>
  );
}

interface FilterType {
  params: any[];
}

function Filter(props: FilterType) {
  const searchParams = props.params[0];
  const setSearchParams = props.params[1];
  const statusSet = (filterValue: string) => {
    let currentParams = Object.fromEntries([...searchParams]);

    // if they are currently selected, remove them from the url
    if (currentParams['status'] === filterValue) {
      delete currentParams['status'];
    } else {
      currentParams['status'] = filterValue;
    }
    setSearchParams(createSearchParams(currentParams));
  };

  const topicsSet = (filterValue: string) => {
    let currentParams = Object.fromEntries([...searchParams]);

    if (currentParams['topics'] === filterValue) {
      delete currentParams['topics'];
    } else {
      currentParams['topics'] = filterValue;
    }
    setSearchParams(createSearchParams(currentParams));
  };

  const sortSet = (sortValue: string) => {
    let currentParams = Object.fromEntries([...searchParams]);
    if (currentParams['sort'] === sortValue) {
      delete currentParams['sort'];
    } else {
      currentParams['sort'] = sortValue;
    }
    setSearchParams(createSearchParams(currentParams));
  };

  const dropdown_ref = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  useOutsideAlerter(wrapperRef, dropdown_ref);

  function setVisible() {
    if (
      dropdown_ref.current !== undefined &&
      dropdown_ref.current.style !== undefined &&
      dropdown_ref.current.style.display !== undefined
    ) {
      dropdown_ref.current.style.display = 'block';
    }
  }
  // Dropdown from Flowbite: https://flowbite.com/docs/components/dropdowns/
  return (
    <div ref={wrapperRef}>
      <div
        ref={dropdown_ref}
        className={`z-10 hidden bg-white divide-y divide-gray-100 rounded shadow absolute w-max ${style.filter}`}
      >
        <div className="bg-white rounded-lg p-4">
          <h1 className="font-semibold text-black text-xl p-1">Filter</h1>
          <div>
            <h3 className="font-semibold text-black text-md p-1">Status</h3>
            <div>
              <Tag
                key={uuidv4()}
                index="unanswered"
                currParams={searchParams.get('status')}
                callback={statusSet}
              />
              <Tag
                key={uuidv4()}
                index="answered"
                currParams={searchParams.get('status')}
                callback={statusSet}
              />
            </div>
            <h3 className="font-semibold text-black text-md p-1">Topics</h3>
            <div>
              <Tag
                key={uuidv4()}
                index="general"
                currParams={searchParams.get('topics')}
                callback={topicsSet}
              />
              <Tag
                key={uuidv4()}
                currParams={searchParams.get('topics')}
                index="web3"
                callback={topicsSet}
              />
              <Tag
                key={uuidv4()}
                currParams={searchParams.get('topics')}
                callback={topicsSet}
                index="machine_learning"
              />
              <Tag
                key={uuidv4()}
                currParams={searchParams.get('topics')}
                callback={topicsSet}
                index="web_dev"
              />
            </div>
          </div>

          <h1 className="font-semibold text-black text-xl p-1">Sort</h1>
          <div>
            {/*TODO REPLACE WITH DROPDOWN*/}
            <Tag
              key={uuidv4()}
              currParams={searchParams.get('sort')}
              callback={sortSet}
              index="relevant"
            />
            <Tag
              key={uuidv4()}
              currParams={searchParams.get('sort')}
              callback={sortSet}
              index="bounty"
            />
            <Tag
              key={uuidv4()}
              currParams={searchParams.get('sort')}
              callback={sortSet}
              index="recency"
            />
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setVisible();
        }}
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
      >
        <IconContext.Provider
          value={{ color: 'white', className: 'h-8 w-8 mr-8' }}
        >
          <IoFilter />
        </IconContext.Provider>
      </button>
    </div>
  );
}

export default Filter;
