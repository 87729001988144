import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
  getHiddenData,
  getPayLogs,
  goToAddBalance,
  goToPaymentLink,
  withdraw,
} from '../../../lib/payments';
import TopBar from '../../components/TopBar';
import ButtonBasic from '../../components/ButtonBasic';
import ButtonStyled from '../../components/ButtonStyled';
import { InputInline } from '../../components/Input';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SideBar from '../../components/SideBar';
import line from '../../../assets/line.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

interface modalType {
  openModal: any;
  closeModal: any;
  modalIsOpen: any;
}
function AddBalanceModal(props: modalType) {
  let [balance, setBalance] = useState<number>(5);

  function afterOpenModal() {
    return null;
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
    >
      <button onClick={props.closeModal} className="float-right text-2xl -mt-1">
        &#10005;
      </button>
      <div>
        <h1>Add to balance (in $)</h1>
        <InputInline
          className="inline"
          value={balance}
          type="number"
          onWheel={(event: any) => {
            event.preventDefault();
          }}
          onChange={async (event: any) => {
            let bountyVal = event.target.value;
            setBalance(parseInt(bountyVal));
          }}
        />
      </div>
      <div className="flex justify-center align-center">
        <ButtonStyled
          title="Add to balance"
          className="mt-4"
          onClick={() => {
            let parsed = balance;

            if (isNaN(parsed) || parsed <= 0) {
              return;
            }

            goToAddBalance(parsed * 100);
          }}
        />
      </div>
    </Modal>
  );
}

function WithdrawModal(props: modalType) {
  let [withdrawAmount, setWithdraw] = useState(5);

  function afterOpenModal() {
    return null;
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={props.closeModal}
      style={customStyles}
    >
      <button onClick={props.closeModal} className="float-right text-2xl -mt-1">
        &#10005;
      </button>
      <div>
        <h1>'How much would you like to withdraw? (in $)</h1>
        <InputInline
          className="inline"
          value={withdrawAmount}
          type="number"
          onWheel={(event: any) => {
            event.preventDefault();
          }}
          onChange={async (event: any) => {
            let bountyVal = event.target.value;
            setWithdraw(parseInt(bountyVal));
          }}
        />
      </div>
      <div className="flex justify-center align-center">
        <ButtonStyled
          title="Withdraw"
          className="mt-4"
          onClick={() => {
            let parsed = withdrawAmount;

            if (isNaN(parsed) || parsed <= 0) {
              return;
            }

            withdraw(parsed * 100).then((body) => {
              if (body.error) {
                alert(body.error);
              } else {
                alert(
                  'Your withdrawal was successful. Your remaining Quidio balance will decrease, and you will receive funds in your bank account soon.'
                );
                window.location.reload();
              }
            });
          }}
        />
      </div>
    </Modal>
  );
}

function BalancePage() {
  let [hiddenData, setHiddenData] = useState<any>();
  let [payLogs, setPayLogs] = useState<any>();
  const [modalIsOpenB, setIsOpenB] = useState(false);
  const [modalIsOpenW, setIsOpenW] = useState(false);
  const [answersAccepted, setanswersAccepted] = useState(0);
  const [lifeTimeEarnings, setLifeTimeEarnings] = useState(0);
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);

  function openModalB() {
    setIsOpenB(true);
  }

  function closeModalB() {
    setIsOpenB(false);
  }

  function openModalW() {
    setIsOpenW(true);
  }

  function closeModalW() {
    setIsOpenW(false);
  }

  useEffect(() => {
    getHiddenData().then(setHiddenData);
    getPayLogs([
      'answer-accepted',
      'balance-add',
      'balance-withdraw',
      'question-posted',
      'question-removed',
      'reward-add',
    ]).then(setPayLogs);
  }, []);

  useEffect(() => {
    if (payLogs) {
      let helped = 0;
      let earnings = 0;
      payLogs.forEach((log: any) => {
        if (log.type === 'answer-accepted') {
          helped++;
          earnings += log.amount;
        }
      });
      setanswersAccepted(helped);
      setLifeTimeEarnings(earnings);
    }
  }, [payLogs]);

  return (
    <>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      {hiddenData === undefined ? (
        <p></p>
      ) : (
        <div
          className={`flex flex-wrap overflow-hidden bg-blue-dark p-10 ${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}
          style={{ backgroundImage: `url(${line})`, 
               backgroundRepeat: 'no-repeat', 
               backgroundSize: "cover", 
              //  width: '100vw',
              //  height: '100vh', 
               backgroundPosition: 'center',
              }}
        >
          <div className="md:w-1/6 w-0 overflow-hidden"></div>
          <div className="md:w-2/3 w-full overflow-hidden">
            <div className="rounded-sm border border-gray-200 bg-white shadow-lg">
              <AddBalanceModal
                openModal={openModalB}
                closeModal={closeModalB}
                modalIsOpen={modalIsOpenB}
              />
              <WithdrawModal
                openModal={openModalW}
                closeModal={closeModalW}
                modalIsOpen={modalIsOpenW}
              />
              <h1 className="m-8 text-5xl">Your Quidio Earnings</h1>
              <div className="flex items-center relative bg-gray-100">
                <div className="w-1/3 my-5 text-center">
                  <h1 className="text-3xl font-bold mb-1">
                    ${((hiddenData.balance || 0) / 100).toFixed(2)}
                  </h1>
                  <p className="text-sm text-blue">
                    +${((hiddenData.reward || 0) / 100).toFixed(2)} Quidio
                    Credit
                  </p>
                  <p className="text-sm">Current Balance</p>
                </div>

                <div className="w-1/3 my-5 text-center">
                  <h1 className="text-3xl font-bold mb-1">{answersAccepted}</h1>
                  {answersAccepted === 1 ? (
                    <p className="text-sm">Person Helped</p>
                  ) : (
                    <p className="text-sm">Accepted Answers</p>
                  )}
                </div>

                <div className="w-1/3 my-5 text-center">
                  <h1 className="text-3xl font-bold mb-1">
                    ${((lifeTimeEarnings || 0) / 100).toFixed(2)}
                  </h1>
                  <p className="text-sm">Lifetime Earnings</p>
                </div>
              </div>

              <div className="my-8 ml-8">
                <div className="flex my-2">
                  <h1 className="text-4xl">Add To Balance</h1>
                  <ButtonBasic
                    className="mx-4 max-h-14"
                    title="Add to balance"
                    onClick={openModalB}
                  />
                </div>
                <p>
                  Add to your internal Quidio balance, which you can use for
                  bounties.
                </p>
              </div>

              <div className="my-8 mx-8">
                <div className="flex my-2">
                  <h1 className="text-4xl">Withdraw Balance</h1>

                  {hiddenData.paymentAccountId ? (
                    <ButtonBasic
                      title="Withdraw"
                      className="mx-4 max-h-14"
                      onClick={openModalW}
                    />
                  ) : (
                    <ButtonBasic
                      title="Register as Answerer"
                      className="mx-4 max-h-14"
                      onClick={() => {
                        goToPaymentLink();
                      }}
                    />
                  )}
                </div>
                {hiddenData.paymentAccountId ? (
                  <p>Withdraw your current Quidio balance via Stripe.</p>
                ) : (
                  <p>
                    Withdraw your current Quidio balance via Stripe. Users must
                    register as an answerer in order to withdraw their balance.
                  </p>
                )}
              </div>
              <div className="my-8 ml-8">
                <div className="flex my-2">
                  <h1 className="text-4xl">Your Transaction History</h1>
                </div>
              </div>

              <div className="flex flex-col mb-8">
                <div className="mx-4">
                  <div className="align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden border-gray-200">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Action
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                          {payLogs ? (
                            payLogs.map((pLog: any) => {
                              if (
                                pLog.type === 'balance-add' ||
                                pLog.type === 'balance-withdraw' ||
                                pLog.type === 'reward-add'
                              ) {
                                return (
                                  <TableRow
                                    key={uuidv4()}
                                    amount={((pLog.amount || 0) / 100).toFixed(
                                      2
                                    )}
                                    time={pLog.timestamp.seconds}
                                    type={pLog.type}
                                  />
                                );
                              } else {
                                return (
                                  <TableRow
                                    key={uuidv4()}
                                    amount={((pLog.amount || 0) / 100).toFixed(
                                      2
                                    )}
                                    time={pLog.timestamp.seconds}
                                    qid={pLog.data.questionId}
                                    type={pLog.type}
                                  />
                                );
                              }
                            })
                          ) : (
                            <> Loading... </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/6 w-0 overflow-hidden"></div>
        </div>
      )}
    </>
  );
}

interface TableRowType {
  key: any;
  amount: any;
  time: any;
  type: any;
  qid?: any;
}

function TableRow(props: TableRowType) {
  var date = new Date(props.time * 1000);
  var humanDateFormat = date.toLocaleString().split(',');
  var newDateFormat = humanDateFormat[0] + '\n' + humanDateFormat[1];

  let link = '';
  if (props.qid) {
    link = '/q/' + props.qid;
  }

  const tagClass =
    'px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-white';

  // string processing
  var amtStr;
  var amtNum = ((props.amount * 100 || 0) / 100).toFixed(2);
  if (props.amount < 0) {
    amtStr = '-$' + amtNum;
  } else {
    amtStr = '+$' + amtNum;
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm">{amtStr}</td>
      <td className="px-6 py-4 text-sm">{newDateFormat}</td>
      <td className="px-6 py-4 text-base">
        <div className="text-sm text-gray-900 break-normal">
          {props.type === 'balance-add' ||
          props.type === 'balance-withdraw' ||
          'reward-add' ? (
            'Balance Change'
          ) : (
            <Link className="hover:underline text-blue-700" to={link}>
              Question
            </Link>
          )}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-gray-500 text-base">
        {props.type === 'question-posted' && (
          <span className={`${tagClass} bg-green-400`}>Question Posted</span>
        )}
        {props.type === 'answer-accepted' && (
          <span className={`${tagClass} bg-blue-400`}>Answer Accepted</span>
        )}
        {props.type === 'balance-add' && (
          <span className={`${tagClass} bg-yellow-400`}>Balance Added</span>
        )}
        {props.type === 'balance-withdraw' && (
          <span className={`${tagClass} bg-red-400`}>Balance Removed</span>
        )}
        {props.type === 'question-removed' && (
          <span className={`${tagClass} bg-purple-400`}>Question Removed</span>
        )}
        {props.type === 'reward-add' && (
          <span className={`${tagClass} bg-pink-400`}>Quidio Credits</span>
        )}
      </td>
    </tr>
  );
}

export default BalancePage;
