import { getAuth } from '@firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {
  getHiddenData
} from '../../lib/payments';
import { getUserPublic } from '../../lib/users';
import ButtonStyled from './ButtonStyled';
import ProfilePhoto from './ProfilePhoto';
import { useOutsideAlerter } from './useOutsideAlerter';
import style from '../../styles/components/TopBar.module.css';

function TopBar() {
  let navigate = useNavigate();
  let [isAuthed, setIsAuthed] = useState(null);
  let [userPublic, setUserPublic] = useState(null);
  let [hiddenData, setHiddenData] = useState(null);
  const dropdown_ref = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, dropdown_ref);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        getUserPublic(user.uid).then(setUserPublic);
        getHiddenData().then(setHiddenData);
        setIsAuthed(true)
      } else {
        setIsAuthed(false)
      }
    });

    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0 }); //behavior: 'smooth' });
  }, []);

  function setVisible() {
    if (dropdown_ref) {
      dropdown_ref.current.style.display = "block"
    }
  }

  const disactiveclass = "md:inline hidden whitespace-nowrap text-black px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
  //const isLanding = window.location.pathname === "/";

  return (
    <nav className="bg-white shadow-sm mb-1" ref={wrapperRef}>
      <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8">
        <div className={`relative flex items-center justify-between h-16 ${style.topbar}`}>
          <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
            {/* LOGO IN TOP LET */}
            {/*{isLanding && (<div className="flex-shrink-0 flex items-center">
              <Link to='/feed'>
                <img className="block lg:hidden h-10 w-auto mx-2" src="/logo.png" />
              </Link>
              <Link to='/feed'>
                <img className="hidden lg:block h-10 w-auto mx-2" src="/logo.png" />
              </Link>
              <span className="font-semibold text-xl"><Link to='/'>Quidio</Link></span>
            </div>)}*/}
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 sm:pr-0">
            <div className="flex space-x-4">
              {isAuthed === false ? (
                <div className="">
                  <ButtonStyled
                    className="cursor-pointer"
                    title="Sign Up"
                    onClick={() => {
                      navigate('/sign-up');
                    }}
                  />{' '}
                  <a href="/log-in" target="_blank">
                    or Log in
                  </a>
                </div>
              ) : (
                <>
                  <Link to={`/balance`} className="md:inline hidden">
                    <div className="border-2 border-black rounded-md text-black py-1 px-1.5">
                      {hiddenData && (
                        <span className="font-medium">${(((hiddenData.balance || 0) + (hiddenData.reward || 0)) / 100).toFixed(2)}</span>
                      )}
                    </div>
                  </Link>
                  {/* <NavLink className={({ isActive }) =>
                    (isActive ? "bg-gray-light " : "") + disactiveclass} to='/feed'>
                    Questions
                  </NavLink> */}
                  <a
                    onClick={() => getAuth().signOut()}
                    href="#"
                    className={disactiveclass}
                  >
                    Sign Out
                  </a>
                </>
              )}
            </div>
            {userPublic && isAuthed && (
              <>
                <div className="ml-3 relative">
                  <div id="profilePhoto" className="float-right relative inline-block">
                    <button onClick={() => {
                      setVisible()
                    }} type="button" className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                      <span
                        className="w-9 h-9 rounded-full bg-blue-800 cursor-pointer"
                      >
                        <ProfilePhoto active={false} edit={false} size="full" />
                      </span>
                    </button>
                    <div ref={dropdown_ref} className={`${style.dropdowncontent}`}>
                      <Link to={`/user/${getAuth().currentUser?.uid}`}>Profile</Link>
                      <Link to="/balance">Balance</Link>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </nav >
  );
}
export default TopBar;
