// @ts-ignore
import Faq from "react-faq-component";
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { IconContext } from 'react-icons';
import { colorConfig } from '../../../config/color.config';
import { BsLightningChargeFill } from 'react-icons/bs';
import { useState } from 'react';

// FAQs page

const data_1 = {
    title: "How It Works",
    rows: [
        {
            title: "What is Quidio?",
            content: `Quidio is a CS marketplace where you can ask a question, add a bounty, and get a great answer.<br/><br/>`,
        },
        {
            title: "Why use Quidio?",
            content: `
                <span class="font-bold"> For Question Askers: </span>
                You’ll receive accurate and fast help with your coding questions. 
                <br/>
                <span class="font-bold"> For Question Answerers: </span>
                You can earn money for your programming expertise. <br/><br/>
                `,
        },
        {
            title: "How does Quidio work?",
            content: `
            <span class="font-bold">1) Ask your question and add a bounty to it.</span>
            <br/>
            Make sure to provide details in the question, especially any details about the answer you want. Be clear about whether you want a bug-fix, a detailed step-by-step tutorial, or something else. And price accordingly!
            <br/>
            <br/>
            <span class="font-bold">2) Your question becomes open to the world.</span>
            <br/>
            Anyone who sees the question can answer it. People who specialize your topic area will be recommended to answer it.            <br/>
            <br/>
            <span class="font-bold">3) Once you receive an answer, accept or reject it.</span>
            <br/>
            If a provided answer solves your problem—great! Accept it and you’re done. If you reject an answer, you must provide a reason for doing so. The answer and the reason it was rejected will become public to anyone on the platform.            <br/>
            <br/>
            For more information on the accept/reject process and our appeals process, see below.            <br/>
            <br/>`,
        },
    ],
};

const data_2 = {
    title: "For Askers",
    rows: [
        {
            title: "What if no one answers my question?",
            content:
                "As long as no one has yet answered your question, you can withdraw the question and receive a full refund, including the service fee.<br/><br/>",
        },
        {
            title: "How do I know I will get a good answer?",
            content: `
                <span class='font-bold'> It's simple: you accept the answer that works for you.</span>
                <br/>
                <br/>
                As answers come in for your question, you can accept or reject them. You get to choose which answer is the best for you, and you award the bounty to that Answerer.
                <br/>
                <br/>
            `,
        },
        {
            title: "How does the accept/reject process work?",
            content: `
                <span class='font-bold'>Quidio allows askers to accept or reject answers that they receive. </span>
                <br/> <br/>
                For instance, if you ask about a code issue and the provided answer does not help fix your issue, you may reject it. Sometimes, someone will provide an initial answer that does not work, see your reason for rejection, and follow up with one that does!                <br/>
                <br/>
                If none of your answers help solve the issue after a long period of time, reject each of them and indicate why you did so. If no good answers ever appear, you can then file an appeal (see below).                <br/>
                <br/> <br/>
            `,
        },
        {
            title: "How do I file an appeal?",
            content: `
            In the very rare cases where answers arrive but none are able to help (i,e. you reject all of them), you can file an appeal for a question refund at founders@quidio.co.            <br/>
            <span class="italic">Every appeal will be manually reviewed. Abuse of this policy will result in instant termination of your Quidio account. </span>
            <br/>
            <br/>
            `,
        },
    ],
};

const data_3 = {
    title: "For Answerers",
    rows: [
        {
            title: "How can I make sure that I am compensated for my answer?",
            content: `
            Because Quidio is a distributed knowledge marketplace, there is a chance that your answer will be rejected when you answer a question.                <br/>
             <br/>  
            Our <span class="font-bold">number one tip</span> to avoid that is simple: respond in detail to the question!
                Many questions will ask about specific problems or request specific response aids (step-by-step list, screenshots, etc.). Be sure to read questions in detail and include those facets.
                <br/> <br/>
                It may also be useful to look out for:
                <br/>
                1. Questions that are genuine (i.e. well-formatted, concise, and clear).
                <br/>
                2. Questions from active Askers (those who have asked/accepted other questions).
                <br/>
                <br/>
            `,
        },
        {
            title: "What if I need more information about a question before I answer it?",
            content: `Sometimes, an asker may not provide enough context for you to write a great answer. In those cases, you may want to ask for clarification in the question’s comments.
            <br/>
            <br/>`,
        },
    ],
};


const data_4 = {
    title: "Depositing and Withdrawing Money",
    rows: [
        {
            title: "How can I deposit money into Quidio?",
            content: `
                Quidio uses <span class="italic">Stripe</span>, a third-party payments platform, to securely handle all monetary transactions on the platform.
                On Stripe, you will link other payment details to deposit money into your Quidio account.
                This will then be reflected in your “balance,” which you can use to answer questions! 
                <br/>
                <br/>
                Click <span class="text-blue-700 hover:underline"><a href="/balance">here</a></span> to add to your balance.
                <br/>
                <br/>
            `,
        },
        {
            title: "How can I withdraw money from Quidio?",
            content: `
            To withdraw money from Quidio, you must officially register as an Answerer. For legal reasons, Stripe will treat you as a contractor for Quidio when you’re on our platform.                 <br/>
                <br/>
                If you face any issues in this process, email us at founders@quidio.co!                
                <br/>
                <br/>
            `,
        },
    ],
};


const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};


function Header() {
    return (
      <section className="text-black flex flex-col items-center bg-blue-dark pb-10">
        <div className="mt-20 mx-10 text-white">
          <h1 className="text-center text-4xl font-bold">
            <IconContext.Provider
              value={{
                color: colorConfig['yellow'],
                className: 'h-12 w-12 inline mx-2',
              }}
            >
              <BsLightningChargeFill />
            </IconContext.Provider>
            <span className="mx-2">Frequently Asked Questions <br/> <br/> </span>
          </h1>
        </div>
      </section>
    );
  }

function QuidioHow() {
    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
    // check if the user is on mobile
    const isMobile = window.innerWidth < 768;

    let defState = true;;
    if(sidebarCollapsed === 'true' || isMobile){
        defState = false;
    }
    else if (sidebarCollapsed === 'false') {
        defState = true;
    }

    const [isExpanded, setExpanded] = useState(defState);
    return (
        <>
            <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
            <TopBar />
            <div className={`${(isExpanded && !isMobile) ? 'ml-60' : 'ml-24'}`}>
                <Header />
                <div className="text-black flex flex-col items-center bg-offwhite pb-10">
                    <div className="mt-10 mx-10">
                        <div className="max-w-3xl rounded-sm border border-gray-200 bg-white shadow-lg">
                            <div className="flex items-center relative mb-10">
                                <div className="rounded-full bg-primary z-30 p-2 inline-block absolute mx-8">
                                    {/*
                                    <div className="fill-current text-white inline-block w-10 h-9" height="72" viewBox="0 0 72 72" width="75">
                                        <img src={questionMark} alt="Question Mark" />
                                    </div>
                                    */}
                                </div>
                            </div>
                            <div className="px-8 pb-4">
                                <br></br>
                                <Faq
                                    data={data_1}
                                    styles={styles}
                                    config={config}
                                />
                                <br></br>
                                <Faq
                                    data={data_2}
                                    styles={styles}
                                    config={config}
                                />
                                <br></br>
                                <Faq
                                    data={data_3}
                                    styles={styles}
                                    config={config}
                                />
                                <br></br>
                                <Faq
                                    data={data_4}
                                    styles={styles}
                                    config={config}
                                />
                                <br></br>
                                {/*
                                <h2 className="text-gray-800 text-xl font-bold pb-1">How Quidio works.</h2>
                                <h2 className="text-gray-800 text-xl font-bold pb-1">Jeffrey, include on this page HOW quidio works. This is the algora-type workflow of ho9w just the entire system works</h2>
                                <p className="text-gray-600 text-sm pb-1">
                                    You have a question: for a project, an assignment, or maybe for work.
                                </p>
                                <p className="text-gray-600 text-sm pb-1">
                                    If you <b>code</b>, you might look to StackOverflow or GitHub.
                                    If you're a <b>hobbyist</b>, you might ask on Quora or a forum.
                                    With high hopes, you write out the question, hoping that a friendly stranger will help...
                                </p>
                                <p className="text-gray-600 text-sm pb-1">
                                    <b>Most of the time, however, your question will slip through the cracks.</b>
                                </p>
                                <p className="text-gray-600 text-sm pb-1">
                                    With so many questions, community volunteers will never get through everything.
                                </p>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuidioHow;
