import QuestionsList from '../../components/QuestionsList';
import TopBar from '../../components/TopBar';
//import Tag from '../../components/Tag';
import SideBar from '../../components/SideBar';
import { useSearchParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import { questionsOptionsType } from '../../../lib/questions';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { MdQuestionAnswer } from 'react-icons/md';
import { colorConfig } from '../../../config/color.config';


/*interface FilterMetaData {
  numQuestionsReturned: number;
}*/
function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  /*const [questionMetadata, setQuestionMetadata] = useState<FilterMetaData>({
    numQuestionsReturned: 0,
  });*/

  let status = (
    searchParams.get('status')
      ? // @ts-ignore
        searchParams.get('status').split(',')
      : []
  ) as questionsOptionsType['status'];

  const topics: string[] = searchParams.get('topics')
    ? // @ts-ignore
      searchParams.get('topics').split(',')
    : [];
  const sort = (searchParams.get('sort') ||
    'relevance') as questionsOptionsType['sort'];
  /*
  const tags: string[] = searchParams.get('tags')
    ? // @ts-ignore
      searchParams.get('tags').split(',')
    : [];
  */


  function Header() {
    return (
      <section className="text-black flex flex-col items-center bg-white pb-10">
        <div className="mt-20 mx-10 text-black">
          <h1 className="text-center text-4xl font-bold">
            <div className="bg-blue-dark w-14 h-14 rounded-full inline-block mx-1">
              <IconContext.Provider
                value={{
                  color: colorConfig['yellow'],
                  className: 'w-8 h-8 inline mx-auto my-3',
                }}
              >
                <MdQuestionAnswer />
              </IconContext.Provider>
            </div>

            <span className="mx-3">Questions</span>
          </h1>
          <h3 className="text-center text-2xl font-medium my-4">
            Find and answer questions
          </h3>
        </div>
      </section>
    );
  }
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);
  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      <div className={`${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <Header />
        <div className="bg-white">
          {/* <div className="p-8"> */}
          {/* <h1 className="text-black font-bold sm:text-3xl lg:text-4xl mb-6">
              Questions
            </h1> */}
          {/*
            {tags.map((tag) => {
              return <Tag name={tag} key={uuidv4()} />;
            })}
          */}
          {/* </div> */}
          <div className="bg-blue-dark p-8 flex justify-between">
            <p className="text-white text-xl font-medium">
              {/*1,000 Questions*/}
            </p>
            <Filter params={[searchParams, setSearchParams]} />
          </div>
          <QuestionsList
            sort={sort}
            status={status}
            limit={16}
            topics={topics}
            paginate={true}
            //tags={tags}
            //metadataSetter={setQuestionMetadata}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
