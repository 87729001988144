import QuestionsList from '../../components/QuestionsList';
import TopBar from '../../components/TopBar';
import ButtonStyled from '../../components/ButtonStyled';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import data from '../../../assets/data-science.png';
import ml from '../../../assets/machine-learning.png';
import web3 from '../../../assets/web3.png';
import webdev from '../../../assets/webdev.png';
import SideBar from '../../components/SideBar';
import { IconContext } from 'react-icons';
import { BsFillPlayCircleFill, BsPeopleFill } from 'react-icons/bs';
import { FaClock } from 'react-icons/fa';
import { MdOutlineLocalFireDepartment } from 'react-icons/md';
import { colorConfig } from '../../../config/color.config';
import style from '../../../styles/pages/unauthed/Feed.module.css';
import { useState, useEffect } from 'react';
import OneSignal from 'react-onesignal';

function Welcome() {
  let navigate = useNavigate();
  return (
    <section className="py-12 bg-white flex flex-wrap overflow-hidden my-1">
      <div className="px-4 lg:px-10 container mx-auto">
        <div className="grid grid-cols-7 gap-5 lg:gap-10 lg:px-4">
          <div className="col-span-7 md:col-span-2 mx-4">
            <h1 className="text-black font-bold text-2xl sm:text-3xl lg:text-4xl my-3">
              Hi there,
            </h1>
            <p className="text-md text-lg font-semibold text-blackQ my-3">
              Use Quidio to answer your programming questions.
            </p>
            <ButtonStyled
              className="cursor-pointer px-3 my-5"
              title="+ Ask Question"
              onClick={() => {
                navigate('/new-question');
              }}
            />
          </div>
          <div className="md:col-span-5 m-auto hidden md:block">
            <h1 className="text-black font-bold text-4xl mb-6">
              Trending topics
            </h1>
            <div className="grid grid-cols-5 gap-3 lg:gap-5">
              <Link to="/search?topics=web_dev">
                <div className="m-auto h-full hover:shadow-2xl">
                  <div className="relative h-full">
                    <img
                      src={webdev}
                      className="object-cover rounded-lg"
                    />
                    <div className="absolute bottom-2 left-2 text-base text-white font-semibold">
                      Web Dev
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/search?topics=web3">
                <div className="m-auto h-full hover:shadow-2xl">
                  <div className="relative h-full">
                    <img
                      src={web3}
                      className="object-cover rounded-lg"
                    />
                    <div className="absolute bottom-2 left-2 text-base text-white font-semibold">
                      Web3
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/search?topics=machine_learning">
                <div className="m-auto h-full hover:shadow-2xl">
                  <div className="relative h-full">
                    <img src={ml} className="object-cover rounded-lg" />
                    <div className="absolute bottom-2 left-2 text-base text-white font-semibold">
                      Machine Learning
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/search?topics=general">
                <div className="m-auto h-full hover:shadow-2xl">
                  <div className="relative h-full">
                    <img
                      src={data}
                      className="object-cover rounded-lg"
                    />
                    <div className="absolute bottom-2 left-2 text-base text-white font-semibold">
                      General
                    </div>
                  </div>
                </div>
              </Link>
              <div className="col-span-1 m-auto w-full h-full">
                <div className="bg-white rounded-xl text-black shadow-md w-full grid place-items-center overflow-hidden">
                  {/*
                  <div className="w-2/3 bg-red-500 h-0" style={{paddingTop: "66%"}}>
                    asf
                  </div>
                  */}
                  <div className="bg-yellow w-full h-2"></div>
                  <div>
                    <div
                      className={`bg-blue-dark rounded-full w-2/3 grid place-items-center mx-auto my-4 ${style.myAspect}`}
                    >
                      <IconContext.Provider
                        value={{
                          color: colorConfig['yellow'],
                          className: 'h-2/3 w-2/3 -mt-1.5 2xl:mt-1',
                        }}
                      >
                        <BsPeopleFill />
                      </IconContext.Provider>
                    </div>
                    <p className="font-bold text-center my-4 text-sm p-4">
                      More topics coming soon
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Line() {
  return <div className="bg-yellow h-0.5 w-full my-4" />;
}

interface HeaderType {
  title: string;
  param: string;
  icon: any;
}

function Header(props: HeaderType) {
  return (
    <div className="md:flex md:justify-between">
      <div className="relative flex items-center h-24 md:mb-4">
        <IconContext.Provider
          value={{ color: colorConfig['yellow'], className: 'h-12 w-12 mx-4' }}
        >
          {props.icon}
        </IconContext.Provider>
        <p className="text-3xl font-semibold text-white">{props.title}</p>
      </div>
      <p className="text-white text-lg font-semibold relative flex items-center md:h-24 md:mb-4 ml-8 md:ml-0 md:mr-8 hover:underline">
        <Link to={`/search/?${props.param}`}>See all 〉</Link>
      </p>
    </div>
  );
}

async function runOneSignal(){
  /*
  OneSignal.init({ appId: '138cd7a7-9b3a-4561-b75a-4f7f443370cc', allowLocalhostAsSecureOrigin: true }).then(() => {
    OneSignal.showSlidedownPrompt().then(() => {
      // do other stuff
      console.log("test");
    });
  })
  */
}
function FeedPage() {
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  // check if user is on mobile
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }


  useEffect(() => {
    runOneSignal();
  });

  const [isExpanded, setExpanded] = useState(defState);
  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      <div className={`${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <Welcome />
        <div className="bg-blue-dark p-4">
          <Header
            title="Popular"
            icon={<BsFillPlayCircleFill />}
            param="sort=relevance"
          />
          <QuestionsList sort="relevance" limit={4} paginate={false} />
          <Line />
          <Header
            title="High bounty"
            icon={<MdOutlineLocalFireDepartment />}
            param="sort=bounty"
          />
          <QuestionsList sort="bounty" limit={4} paginate={false} />
          <Line />
          <Header
            title="Unanswered"
            icon={<FaClock />}
            param="sort=recency&status=unanswered"
          />
          <QuestionsList status={['unanswered']} sort="recency" limit={4} paginate={false} />
        </div>
      </div>
    </div>
  );
}
export default FeedPage;
