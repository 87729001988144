export interface errorType {
  error: boolean;
  message?: string;
}

export interface error {
  title: errorType;
  topic: errorType;
  body: errorType;
  tags: errorType;
}

export const topicOptions = [
  { value: 'web_dev', label: 'Web Dev' },
  { value: 'machine_learning', label: 'Machine Learning' },
  { value: 'web3', label: 'Web 3' },
  { value: 'general', label: 'General' },
];

export const getTopicOptions = (value: string) => {
  return topicOptions.filter((topic) => topic.value === value);
}

export function validateForm(
  bounty: number,
  title: string,
  topic: string,
  body: string,
  tags: string
): error {
  const defaultError: errorType = { error: false };
  let error_message: error = {
    title: structuredClone(defaultError),
    topic: structuredClone(defaultError),
    body: structuredClone(defaultError),
    tags: structuredClone(defaultError),
  };

  if (title === '') {
    error_message.title.error = true;
    error_message.title.message = 'Please add a title';
  }

  if (topic === '') {
    error_message.topic.error = true;
    error_message.topic.message = 'Please select a topic';
  }

  if (tags === '') {
    error_message.tags.error = true;
    error_message.tags.message = 'Please select at least one tag';
  }

  if (body === '') {
    error_message.body.error = true;
    error_message.body.message = 'Please add a body';
  }

  return error_message;
}
