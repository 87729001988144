// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { getAuth } from '@firebase/auth';
import { IconContext } from 'react-icons';
import { BsFillLightbulbFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { submitQuestion, tryPayment } from '../../../lib/questions';
import ButtonStyled from '../../components/ButtonStyled';
import Editor from '../../components/Editor';
import InputWithLabel from '../../components/InputWithLabel';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { getHiddenData } from '../../../lib/payments';
import devConfig from '../../../config/config.dev.json';
import prodConfig from '../../../config/config.prod.json';
import Select from 'react-select';
import {
  validateForm,
  error,
  topicOptions,
} from '../../components/ValidateForm';
import { colorConfig } from '../../../config/color.config';

const project = process.env.REACT_APP_FIREBASE_PROJECT;
const config = project === 'dev' ? devConfig : prodConfig;

function NewQuestionPage() {
  let navigate = useNavigate();
  let [bounty, setBounty] = useState<number>(10);
  let [title, setTitle] = useState('');
  let [tags, setTags] = useState('');
  let [error, setError] = useState<error>({
    title: { error: false },
    topic: { error: false },
    body: { error: false },
    tags: { error: false },
  });
  let mdeRef = useRef(null);
  let parsedBounty = bounty;
  let [hiddenData, setHiddenData] = useState(null);
  const defaultTopic = [{ value: 'general', label: 'General' }];
  let [topic, setTopic] = useState<string>(defaultTopic[0].value);

  function success_message(parsedBounty: number) {
    let amountCharged =
      hiddenData?.reward > 0
        ? (
            parsedBounty +
            parsedBounty * config.serviceFee -
            hiddenData?.reward / 100
          ).toFixed(2)
        : (parsedBounty + parsedBounty * config.serviceFee).toFixed(2);
    let amountChargedMax = Math.max(0, amountCharged);
    return isNaN(parsedBounty)
      ? ''
      : `You will be charged $${amountChargedMax}, which includes the service fee of ${(
          config.serviceFee * 100
        ).toFixed()}%.`;
  }

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        getHiddenData().then(setHiddenData);
      }
    });
  }, []);

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);

  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded}/>
      <TopBar />
      <div className={`flex flex-wrap overflow-hidden p-5 bg-offwhite ${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <div className="lg:w-1/6 md:w-1/6 w-0 overflow-hidden"></div>
        <div className="lg:w-2/3 md:w-2/3 w-full px-2 overflow-hidden">
          <div className="bg-white rounded-md mb-4 mx-1 shadow-md">
            <div className="px-4 py-4">
              <p className="text-xl font-medium my-1 flex justify-between">
                <IconContext.Provider
                  value={{ color: colorConfig['yellow'], className: 'h-12 w-12' }}
                >
                  <BsFillLightbulbFill />
                </IconContext.Provider>
                <span className="ml-4">
                  Upon posting your question, anybody can answer it. Select the
                  answer that works for you and they'll receive your bounty.
                </span>
              </p>
            </div>
          </div>
          <div className="bg-white rounded-md p-4 mb-4 mx-1 shadow-md">
            <h3 className="text-lg font-bold mb-2">New question</h3>
            <InputWithLabel
              title="Bounty"
              toolTip="The value of the question. The accepted answerer will receive the bounty. Higher bounties attract more attention."
              inputProps={{
                value: bounty,
                type: 'number',
                onWheel: (event) => {
                  event.preventDefault();
                },
                onChange: async (event) => {
                  let bountyVal = event.target.value;
                  setBounty(parseInt(bountyVal));
                  let response = await tryPayment(
                    parseInt(bountyVal) * 100 +
                      parseInt(bountyVal) * 100 * config.serviceFee,
                    config.minBounty
                  );
                  if (response.error) {
                    if (response.type === 'minimumBounty') {
                      document.getElementById(
                        'warning'
                      ).innerHTML = `There is a minimum bounty of at least $10`;
                      document
                        .getElementById('warning')
                        .classList.add('text-red-600');
                    } else if (response.type === 'insufficientFunds') {
                      document.getElementById(
                        'warning'
                      ).innerHTML = `Insufficient balance for a bounty of $${bountyVal} and fee of $${(
                        parseInt(bountyVal) * config.serviceFee
                      ).toFixed(
                        2
                      )}. <br/> Please <a href="/balance" target="_blank" class="underline hover:underline">add to your balance</a>.`;
                      document
                        .getElementById('warning')
                        .classList.add('text-red-600');
                    } else if (response.type === 'invalidPayment') {
                      document.getElementById(
                        'warning'
                      ).innerHTML = `Invalid payment.`;
                      document
                        .getElementById('warning')
                        .classList.add('text-red-600');
                    }
                  } else {
                    document.getElementById('warning').innerHTML =
                      success_message(parseInt(bountyVal));
                    document
                      .getElementById('warning')
                      .classList.remove('text-red-600');
                  }
                },
              }}
            />
            <p className="text-green-500">
              {hiddenData?.reward > 0 && (
                <p>
                  You have ${hiddenData?.reward / 100} in free Quidio credit
                </p>
              )}
            </p>

            <p className="mb-4 text-gray-500" id="warning">
              {success_message(parsedBounty)}
            </p>

            <InputWithLabel
              title="Question title"
              error={error.title.message}
              inputProps={{
                value: title,
                onChange: (event) => setTitle(event.target.value),
                className: 'w-full',
              }}
            />

            <p>Topic</p>
            {error.topic.error && (
              <p className="text-red-500">{error.topic.message}</p>
            )}

            <Select
              defaultValue={defaultTopic}
              options={topicOptions}
              className="w-full z-10"
              onChange={(event) => setTopic(event.value)}
            />

            <InputWithLabel
              title="Tags (comma-separated)"
              error={error.tags.message}
              inputProps={{
                value: tags,
                onChange: (event) => setTags(event.target.value),
                className: 'lowercase w-full',
              }}
            />

            <p>Question body</p>
            {error.body.error && (
              <p className="text-red-500">{error.body.message}</p>
            )}
            <Editor ref={mdeRef} />
            <ButtonStyled
              size="large"
              title="Ask Question"
              onClick={async () => {
                let error = validateForm(
                  bounty,
                  title,
                  topic,
                  mdeRef.current.value(),
                  tags
                );
                if (
                  error.title.error ||
                  error.topic.error ||
                  error.body.error ||
                  error.tags.error
                ) {
                  setError(error);
                  return;
                }

                if (!mdeRef.current) {
                  return;
                }

                let response = await submitQuestion(
                  bounty * 100,
                  title,
                  topic,
                  mdeRef.current.value(),
                  tags
                );

                if (response.error) {
                  alert(response.error);
                } else {
                  navigate('/feed');
                }
              }}
            />
          </div>
        </div>
        <div className="lg:w-1/6 md:w-1/6 w-0 overflow-hidden"></div>
      </div>
    </div>
  );
}

export default NewQuestionPage;
