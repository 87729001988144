import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import SimpleMDE from 'simplemde';

let Editor = forwardRef((props, ref) => {
  let simpleMde = useRef(null);

  useImperativeHandle(ref, () => ({
    value: () => {
      if (simpleMde.current) {
        return simpleMde.current.value();
      }
    },
  }));

  useEffect(() => {
    let newMde = new SimpleMDE({
      element: document.getElementById('md-editor'),
      spellChecker: false,
      renderingConfig: {
        codeSyntaxHighlighting: true,
      },
      initialValue: props.default ?? ''
    });

    simpleMde.current = newMde;
  }, [props.default]);

  return <textarea id="md-editor" />;
});

export default Editor;
