import { getAuth } from '@firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { listenForAnswers } from '../../../lib/answers';
import { getQuestion, listenForQuestions, questionsOptionsType } from '../../../lib/questions';
import { getUserPublic, updateCurrentUser } from '../../../lib/users';
import YellowButton from '../../components/YellowButton';
import ProfilePhoto from '../../components/ProfilePhoto';
import { QuestionPreview } from '../../components/QuestionsList';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { User } from '@firebase/auth/dist/auth-public'
import { QuestionType, UserType } from '../../../firestoreSchema';


function UserPage(props: {filter: "questions" | "answers"}) {
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);

  let navigate = useNavigate();
  let [currUser, setCurrUser] = useState<User>();
  let params = useParams();
  let [userPublic, setUserPublic] = useState<UserType|null>();

  useEffect(() => {
    if(params.uid){
      getUserPublic(params.uid).then((data) => {
        setUserPublic(data);
      });
    }
  }, [params.uid]);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setCurrUser(user);
      }
    });
  }, []);

  // Create a root reference
  const storage = getStorage();
  // @ts-ignore
  const storageRef = ref(storage, '/blank/profile');
  if(currUser?.uid){
    ref(storage, '/' + currUser.uid + '/profile');
  }
  const upload = (image: any) => {
    if (image == null)
      return;
    else if (!["image/png", "image/gif", "image/jpeg"].includes(image.type)) {
      alert("Invalid file type, please upload PNG, GIF, or JPEG")
      return;
    }

    const uploadTask = uploadBytesResumable(storageRef, image);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            alert("User doesn't have permission to access the object")
            break;
          case 'storage/canceled':
            alert("User canceled the upload")
            break;
          case 'storage/unknown':
            alert("Unknown error occurred, inspect error.serverResponse")
            break;
          default:
            alert("error")
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updateCurrentUser({
            photoURL: downloadURL,
          }).then(() => window.location.reload());
        });
      }
    )
  }

  function Content(content_props: {isExpanded: boolean, filter: "questions" | "answers"}) {
    const isMobile = window.innerWidth < 768;
    return (
      <section className={`text-black flex flex-col items-center bg-blue-dark pb-10 ${(content_props.isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <div className="mt-20 mx-10 text-white w-4/5">

          {userPublic && (

            <div className="flex items-center mx-8">
              <div className="flex pr-4">
                {currUser?.uid === params.uid ? (
                  <ProfilePhoto user={userPublic} upload={upload} edit={true} />
                ) : (
                  <ProfilePhoto user={userPublic} />
                )}
              </div>
              <div className="ml-4">
                <div className="text-4xl font-semibold">{userPublic.name ?? 'User'}</div>

                <p className="font-code text-white mt-4 mb-6 text-lg">
                  {(userPublic.bio || userPublic.bio === " ") ?? 'This user does not have a bio.'}
                </p>

                <div className="space-x-4">
                  {currUser?.uid === params.uid && (
                    <>
                      <YellowButton
                        title="Edit Profile"
                        className="text-xs"
                        onClick={() => {
                          navigate('/user/edit');
                        }}
                      />
                      <YellowButton
                        title="Balance"
                        className="text-xs"
                        onClick={() => {
                          navigate('/balance');
                        }}
                      />
                    </>
                  )}

                </div>

              </div>
            </div>
          )}

          <div className="pt-8">
            <FilterNav filter={props.filter} />
          </div>

        </div>
      </section>
    );
  }

  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      <Content isExpanded={isExpanded} filter={props.filter}/>
    </div>
  );
}

function FilterNav(props: {filter: "questions" | "answers"}) {
  let [questionIds, setQuestionIds] = useState<string[]>([]);
  let [questionData, setQuestionData] = useState<QuestionDict>({});
  let [currUser, setCurrUser] = useState<'Loading' | User | boolean>('Loading');
  //console.log("IMPORATNT CAINE")
  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setCurrUser(user);
      } else {
        setCurrUser(false)
      }
    });
  }, []);

  type optionType = {value: questionsOptionsType['sort'], label: string};
  type QuestionDict = {[key: string]: QuestionType};
  let options: optionType[] = [];
  if (props.filter === 'questions') {
    options = [{ value: 'recency', label: 'Most Recent' }, { value: 'bounty', label: 'Highest Bounty' }]
  } else if (props.filter === 'answers') {
    options = [{ value: 'recency', label: 'Most Recent' }]
  }

  let [selectedOption, ] = useState<optionType>(options[0]);

  useEffect(() => {
    if (props.filter === 'questions') {
      listenForQuestions({ currUser: params.uid, sort: selectedOption.value }, (data) => {
        let question_dict: QuestionDict = Object.assign({}, ...data.map((question) => ({ [question.id]: question })));
        setQuestionData(question_dict)

        let temp_ids: string[] = [];
        data.forEach(function (item, index) {
          temp_ids.push(item.id)
        });
        setQuestionIds(temp_ids)
      })
    } else if (props.filter === 'answers') {
      listenForAnswers({ filter: selectedOption.value!, currUser: params.uid }, (data) => {
        let temp_ids: string[] = [];
        data.forEach(function (item, index) {
          temp_ids.push(item.parent)
        });
        // remove duplicates from array
        temp_ids = temp_ids.filter((item, index) => temp_ids.indexOf(item) === index);
        setQuestionIds(temp_ids)

        for (let id of temp_ids) {
          getQuestion(id).then((newQuestionData) => {
            setQuestionData((oldQuestionData) => ({
              ...oldQuestionData,
              [id]: newQuestionData,
            }));
          });
        }
      })
    }
  }, [params.uid, selectedOption, props.filter, currUser, navigate]);

  if (props.filter === 'questions') {
    return (
      <>
        <Tabs />
        <div className="px-8 pt-1 pb-4">
          <div className="bg-white h-px w-full" />
        </div>
        {questionIds.length !== 0 ? (
          <div className="my-3 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">

            {/* <Select
              defaultValue={options[0]}
              onChange={setSelectedOption}
              options={options}
              className="w-1/3 px-2 mb-4"
            /> */}

            {questionIds && (
              questionIds.map(
                (id) => {
                  return questionData[id] && <QuestionPreview key={uuidv4()} question={questionData[id]} allowMarkdown={false} />
                }
              )
            )}
          </div>
        ) : (
          <div className="my-3 mx-8">
            {/* @ts-ignore */}
            {(currUser.uid === params.uid) ? (
              <p>You haven't asked any questions yet! Ask your first question <Link className="hover:underline text-yellow" to="/new-question">here.</Link></p>
            ):(
              <p>This user hasn't asked any questions yet.</p>
            )}
          </div>
        )}
      </>
    )
  } else if (props.filter === 'answers') {
    return (
      <>
        <Tabs />
        <div className="px-8 pt-1 pb-4">
          <div className="bg-white h-px w-full" />
        </div>
        {questionIds.length !== 0 ? (
          <div className="my-3 grid grid-cols-3">
            {/* <Select
                defaultValue={options[0]}
                onChange={setSelectedOption}
                options={options}
                className="w-1/3 px-2 mb-4"
              /> */}
            {questionIds && (
              questionIds.map(
                (id) => {
                  return questionData[id] && <QuestionPreview key={uuidv4()} question={questionData[id]} allowMarkdown={false} />
                }
              )
            )}
          </div>
        ) : (
          <div className="my-3 mx-8">
            {/* @ts-ignore */}
            {currUser.uid === params.uid ? (
              <p>You haven't answered any questions yet! Answer your first question <Link className="hover:underline text-yellow" to="/feed">here</Link>.</p>
            ) : (
              <p>This user does not have any answers.</p>
            )}
          </div>
        )}
      </>
    )
  } else {
    return null;
  }
}

function Tabs() {
  let params = useParams();

  const questions = "/user/" + params.uid + "/questions";
  const answers = "/user/" + params.uid + "/answers";
  const location = useLocation();

  function HighlightTab({ children, to, alt, className }: {children: any, to: string, alt?: string, className: string}) {
    const match = to === location.pathname || (alt === location.pathname);
    const activeClassName = "font-semibold hover:text-white ";
    return (
      <Link className={(match ? activeClassName : '') + "text-xl mr-4 hover:text-white"} to={to}>{children}</Link>
    )
  }
  return (
    <div>
      <div className="md:flex justify-between text-center mx-8">
        <div>
          <HighlightTab className="text-xl mr-4 hover:text-yellow-light" to={questions} alt={"/user/" + params.uid}>Questions</HighlightTab>
          <HighlightTab className="text-xl mr-4 hover:text-yellow-light" to={answers}>Answers</HighlightTab>
        </div>
      </div>
    </div>

  )
}
export default UserPage;
