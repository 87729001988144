import { getAuth } from '@firebase/auth';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import plus from '../../assets/plusQuidio.png';
import { getUserPublic } from '../../lib/users';
import style from '../../styles/pages/unauthed/User.module.css';

//  size (optional): how big of a pfp to display | Default="small"
//  edit (optional): whether to allow the user to "click" on the pfp to edit it
//  user (optional): if this is provided, display the pfp of the user associated with this document
// e.g. user  = {bio: "This is my bio", name: "Caine Ardayfio", uid: "83923892", photoURL: "JKLDJKL"}
// if it is not provided, display the current user's pfp
interface ProfilePhotoType {
  size?: 'xs' | 'small' | 'medium' | 'full';
  edit?: boolean;
  user?: any;
  className?: string;
  upload?: any;
  active?: any;
}

function ProfilePhoto(props: ProfilePhotoType) {
  let size;

  interface userInfo {
    loading: boolean;
    userInfo: any;
  }
  let [userPublic, setUserPublic] = useState<userInfo>({
    loading: true,
    userInfo: null,
  });

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        //getUserPublic(user.uid).then(setUserPublic);
        getUserPublic(user.uid).then((val) => {
          setUserPublic({ loading: false, userInfo: val });
        });
      } else {
        setUserPublic({ loading: false, userInfo: false });
      }
    });
  }, []);

  if (props.size === 'xs') {
    size = 'w-6 h-6';
  } else if (props.size === 'small') {
    size = 'w-12 h-12';
  } else if (props.size === 'medium') {
    size = 'w-28 h-28';
  } else if (props.size === 'full') {
    size = 'full';
  } else {
    size = 'w-36 h-36';
  }

  let user;
  if (!props.user && !userPublic.loading) {
    user = userPublic.userInfo;
  } else if (!props.user && userPublic.loading) {
    return null;
  } else if (props.user) {
    user = props.user;
  }

  if (props.edit) {
    return (
      <div
        className={`cursor-pointer ${size} inline-block rounded-full bg-white relative ${
          props.className ?? ''
        }`}
      >
        <img className="h-full w-full rounded-full block" src={user.photoURL} />
        {props.edit && (
          <div className="absolute top-0 bottom-0 left-0 right-0 h-full w-full rounded-full">
            <label htmlFor="file-input">
              <img
                className={`absolute top-1/2 left-1/2 text-white text-2xl text-center hover:opacity-100 cursor-pointer ${style.myImg}`}
                src={plus}
              />
            </label>
            <input
              type="file"
              id="file-input"
              accept="image/png, image/gif, image/jpeg"
              className="hidden"
              onChange={(e) => {
                props.upload(e.target.files?.[0]);
              }}
            />
          </div>
        )}
      </div>
    );
  } else {
    if (props.active || props.active === undefined) {
      return (
        <Link className="cursor-pointer" to={`/user/${user.uid}`}>
          <div
            className={`${size} inline-block rounded-full bg-white relative ${
              props.className ?? ''
            }`}
          >
            <img
              className="h-full w-full rounded-full block"
              src={user.photoURL}
            />
            {props.edit && (
              <div className="absolute top-0 bottom-0 left-0 right-0 h-full w-full rounded-full">
                <label htmlFor="file-input">
                  <img
                    className={`absolute top-1/2 left-1/2 text-white text-2xl text-center hover:opacity-100 cursor-pointer ${style.myImg}`}
                    src={plus}
                  />
                </label>
                <input
                  type="file"
                  id="file-input"
                  accept="image/png, image/gif, image/jpeg"
                  className="hidden"
                  onChange={(e) => {
                    props.upload(e.target.files?.[0]);
                  }}
                />
              </div>
            )}
          </div>
        </Link>
      );
    } else {
      return (
        <div
          className={`${size} inline-block rounded-full bg-white relative ${
            props.className ?? ''
          }`}
        >
          <img
            className="h-full w-full rounded-full block"
            src={user.photoURL}
          />
          {props.edit && (
            <div className="absolute top-0 bottom-0 left-0 right-0 h-full w-full rounded-full">
              <label htmlFor="file-input">
                <img
                  className={`absolute top-1/2 left-1/2 text-white text-2xl text-center hover:opacity-100 cursor-pointer ${style.myImg}`}
                  src={plus}
                />
              </label>
              <input
                type="file"
                id="file-input"
                accept="image/png, image/gif, image/jpeg"
                className="hidden"
                onChange={(e) => {
                  props.upload(e.target.files?.[0]);
                }}
              />
            </div>
          )}
        </div>
      );
    }
  }
}

export default ProfilePhoto;
