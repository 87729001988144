import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { editQuestion } from '../../../lib/questions';
import ButtonStyled from '../../components/ButtonStyled';
import Editor from '../../components/Editor';
import InputWithLabel from '../../components/InputWithLabel';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { useParams } from 'react-router';
import { getQuestion } from '../../../lib/questions';
import { getAuth } from '@firebase/auth';
import Select from 'react-select';
import { validateForm, error, topicOptions } from '../../components/ValidateForm';

function EditQuestionPage() {
  let navigate = useNavigate();
  let [bounty, setBounty] = useState(5);
  let [title, setTitle] = useState('');
  let [error, setError] = useState<error>({ title: { error: false }, topic: { error: false }, body: { error: false }, tags: { error: false } });
  let [topic, setTopic] = useState<string>('');
  let [body, setBody] = useState(undefined);
  let [tags, setTags] = useState('');
  let mdeRef = useRef(null);
  let params = useParams();

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);

  useEffect(() => {
    getQuestion(params.id).then((question) => {
      setTags(question.tags.join(", "))
      setTitle(question.title)
      setBounty(question.bounty / 100)
      setBody(question.bodySource ?? '');

      getAuth().onAuthStateChanged((user) => {
        if (!user) {
          navigate('/feed')
        }
        if (user?.uid !== question.asker) {
          navigate('/feed')
        }
      });
    });
  }, [params.id, navigate]);

  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      <div className={`flex flex-wrap overflow-hidden my-2 bg-offwhite ${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <div className="md:w-1/6 w-0 overflow-hidden"></div>
        <div className="md:w-2/3 w-full overflow-hidden">
          <div className="bg-white rounded-md p-4 mb-4 mx-1 shadow-md">
            <h3 className="text-lg font-bold mb-2">Edit question</h3>
            <InputWithLabel
              title="Bounty"
              toolTip="The value of the question. The accepted answerer will receive the bounty."
              inputProps={{
                value: bounty,
                type: 'number',
                onChange: (event: any) => setBounty(parseInt(event.target.value)),
                onWheel: (event: any) => {
                  event.preventDefault();
                },
                disabled: true
              }}
            />
            <InputWithLabel
              title="Question title"
              error={error.title.message}
              inputProps={{
                value: title,
                onChange: (event: any) => setTitle(event.target.value),
                className: "w-full"
              }}
            />

            <p>Topic</p>
            {error.topic.error && <p className="text-red-500">{error.topic.message}</p>}

            <Select
              options={topicOptions}
              className="w-full mb-2 z-10"
              onChange={(event) => {
                if(event){setTopic(event.value)}
              }}
            />

            <InputWithLabel
              title="Tags (comma-separated)"
              error={error.tags.message}
              inputProps={{
                value: tags,
                onChange: (event: any) => setTags(event.target.value),
                className: "lowercase w-full"
              }}
            />

            <p>Question body</p>
            {/*
            // @ts-ignore */}
            {body !== undefined ? <Editor default={body} ref={mdeRef} /> : null}
            <ButtonStyled
              size="large"
              title="Edit Question"
              onClick={async () => {
                /*
                // @ts-ignore */
                let bodVal = mdeRef.current.value();
                let error = validateForm(bounty, title, topic, bodVal, tags);
                if (error.title.error || error.topic.error || error.body.error || error.tags.error) {
                  setError(error);
                  return;
                }

                if (!mdeRef.current) {
                  return;
                }
                let response = await editQuestion(
                  params.id,
                  bounty * 100,
                  title,
                  topic,
                  bodVal,
                  tags
                );
                if (response.error) {
                  alert(response.error);
                } else {
                  if(params.id) {
                    navigate('/q/'.concat(params.id));
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="md:w-1/6 w-0 overflow-hidden"></div>
      </div>
    </div>
  );
}

export default EditQuestionPage;
