import { useEffect } from 'react';

export function useOutsideAlerter(ref, dropdown_ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (dropdown_ref.current) {
                    var dropdowns = dropdown_ref.current;
                    if (dropdowns.style.display === 'block') {
                        dropdowns.style.display = 'none';
                    }
                }
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, dropdown_ref]);
}