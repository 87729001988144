import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';

export const partnerOrgs = [
  { value: 'hcs', label: 'Harvard Computer Society' },
  { value: 't4sg', label: 'Tech 4 Social Good' },
  { value: 'wics', label: 'Women in Computer Science' },
  { value: 'hdag', label: 'Harvard Data Analytics Group' },
  { value: 'AutumnHacks', label: 'Autumn Hacks'}
];

export async function getOrgs(org: string){
  const orgs = await getDoc(doc(getFirestore(), 'metadata', 'organizations'));
  const org_data = orgs.data()
  if(org_data && org_data[org]){
    console.log(org)
    console.log(org_data)
    return {value: org, label: org_data[org]['label'], price: org_data[org]['price']};
  }
  return undefined
  // orgs.data = {hcs:}
}

export function isOrg(org: string) {
  // const a = await getDoc(doc(getFirestore(), 'users', org));
  console.log(partnerOrgs.find(o => o.value === org))
  return partnerOrgs.find(o => o.value === org);
}
