import { getAuth } from '@firebase/auth';
import { useEffect, useState } from 'react';
import UnauthedLogInPage from '../pages/unauthed/LogIn';

const PrivateRoute = ({ children }) => {
  let [isAuthed, setIsAuthed] = useState({ notLoaded: true, loggedIn: false });
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setIsAuthed({ notLoaded: false, loggedIn: true })
      } else {
        setIsAuthed({ notLoaded: false, loggedIn: false });
      }
    })
  }, []);
  if (isAuthed.notLoaded) {
    return (
      null
    )
  } else if (isAuthed.loggedIn) {
    return children
  }

  //not logged in
  return (
    <UnauthedLogInPage next={window.location.pathname}/>
    //<Navigate to={{ pathname: '/log-in' }} />
  )
}

export default PrivateRoute;