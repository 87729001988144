import { getAuth } from '@firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getUserPublic, updateCurrentUser } from '../../../lib/users';
import ProfilePhoto from '../../components/ProfilePhoto';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import YellowButton from '../../components/YellowButton';

function UserEditPage() {
  let navigate = useNavigate();
  let [bio, setBio] = useState('');

  let [isAuthed, setIsAuthed] = useState('');
  let [currUser, setCurrUser] = useState('');
  let [userPublic, setUserPublic] = useState(null);
  let params = useParams();

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        setCurrUser(user);
        getUserPublic(user.uid).then(setUserPublic);
        setIsAuthed(true);
      } else {
        setIsAuthed(false);
      }
    });
  }, []);

  useEffect(() => {
    getUserPublic(params.uid).then(setUserPublic);
  }, [params.uid]);


  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);

  return (
    <div>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      <div className={`flex flex-wrap overflow-hidden p-5 bg-blue-dark items-center ${(isExpanded && !isMobile) ? "ml-60" : "ml-24"}`}>
        <div className="md:w-1/6 w-0 overflow-hidden"></div>
        <div className="md:w-2/3 overflow-hidden items-center">
          <div className="flex items-center p-5">
              {isAuthed && userPublic && (
                <>
                  <ProfilePhoto user={userPublic} size="medium" edit={false} />
                  <div className="ml-4 w-full">
                  <div className="text-white font-semibold text-2xl sm:text-3xl lg:text-4xl my-3">
                    {userPublic.name ?? 'User'}
                  </div>
                    <textarea 
                      className='border w-4/5 rounded'
                      rows='6'
                      placeholder={(userPublic.bio || userPublic.bio === " ") ?? 'Bio'}
                      onChange={(event) => {
                        setBio(event.target.value)
                      }}
                    />
                    <YellowButton
                      size="large"
                      title="Save changes"
                      onClick={async () => {
                        let response = updateCurrentUser({
                          bio: bio,
                        });

                        if (response.error) {
                          alert(response.error);
                        } else {
                          navigate('/user/' + currUser.uid);
                        }
                      }}
                    />
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="md:w-1/6 w-0 overflow-hidden"></div>
      </div>
    </div>
  );
}


export default UserEditPage;
