// To create an Input with a specific class name, set the className prop to the class name you want.
// E.g. <Input className="my-custom-class-name" />, which is equivalent to the following, 
// <input className="my-custom-class-name" className="p-1 ... block my-custom-class-name" />, which is equivalent to the following, 
// <input className="p-1 ... block my-custom-class-name" />

function Input(props) {
  return (
    <input {...props} className={`p-1 border-2 border-gray-300 rounded-sm block ${props.className ?? ''}`} />
  );
}

function InputInline(props) {
  return (
    <input {...props} className={`p-1 border-2 border-gray-300 rounded-sm inline ${props.className ?? ''}`} />
  );
}

export { Input, InputInline };
