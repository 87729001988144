import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import quidio_icon from '../../../assets/quidio-logo2.svg';
import about_img1 from '../../../assets/about_img1.svg';
import about_img2 from '../../../assets/about_img2.svg';
import about_img3 from '../../../assets/about_img3.svg';
import about_img4 from '../../../assets/about_img4.svg';
import about_img5 from '../../../assets/about_img5.svg';
import about_img6 from '../../../assets/about_img6.svg';
import { IconContext } from 'react-icons';
import { BsQuestionLg } from 'react-icons/bs';
import { colorConfig } from '../../../config/color.config';
import {
  MdChat
} from 'react-icons/md';
import React, { useState } from 'react';
import { FaLightbulb } from 'react-icons/fa';
// How It Works page

interface HeaderProp {
  callback: any;
  isAsker: boolean;
  isExpanded: boolean;
  isMobile: boolean;
}

function Header(props: HeaderProp) {
  return (
    <section className={`text-black flex flex-col items-center bg-blue-dark pb-10 ${(props.isExpanded && !props.isMobile) ? "ml-60" : "ml-24"}`}>
      <div className="mt-20 mx-10 text-white">
        <h1 className="text-center text-4xl font-bold">
          <IconContext.Provider
            value={{
              color: colorConfig['yellow'],
              className: 'h-12 w-12 inline mx-2',
            }}
          >
            <FaLightbulb />
          </IconContext.Provider>
          <span className="mx-2">How it works</span>
        </h1>
        <h3 className="text-center text-2xl font-medium mt-4">
          It's simple: ask a question, add a bounty, get a great answer
          <br />
          <br />
        </h3>
        {props.isAsker ? (
          <div className="flex items-center justify-center">
            <button
              className="border border-yellow bg-yellow text-blue-dark text-sm font-bold py-2 px-4 rounded-l w-1/3 md:w-1/5"
              onClick={() => props.callback(true)}
            >
              For Askers
            </button>
            <button
              className="border border-yellow bg-blue-dark hover:bg-blue text-sm text-yellow font-bold py-2 px-4 rounded-r w-1/3 md:w-1/5"
              onClick={() => props.callback(false)}
            >
              For Answerers
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <button
              className="border border-yellow bg-blue-dark hover:bg-blue text-yellow text-sm font-bold py-2 px-4 rounded-l w-1/3 md:w-1/5"
              onClick={() => props.callback(true)}
            >
              For Askers
            </button>
            <button
              className="border border-yellow bg-yellow text-sm text-blue-dark font-bold py-2 px-4 rounded-r w-1/3 md:w-1/5"
              onClick={() => props.callback(false)}
            >
              For Answerers
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

function ForAskers(props: { isExpanded: boolean, isMobile: boolean }) {
  return (
    <section className={`text-black flex flex-col items-center bg-offwhite pb-10 ${(props.isExpanded && !props.isMobile) ? "ml-60" : "ml-10"}`}>
      <div className="grid md:grid-cols-5 ml-24 mr-8 md:mx-20 lg:mx-32">
        {/* Section 1 */}
        <div className="md:col-span-3 py-24">
          <div className="bg-blue-dark w-16 h-16 rounded-full inline-block mx-1">
            <div className="w-full h-full">
              <IconContext.Provider
                value={{
                  color: colorConfig['yellow'],
                  className: 'w-10 h-10 block mx-auto mt-3',
                }}
              >
                <BsQuestionLg />
              </IconContext.Provider>
            </div>
          </div>
          <h3 className="text-2xl md:text-2xl font-semibold my-3">
            Ask your question
          </h3>
          <p className="mt-5 w-5/6">
            You have a technical problem that needs to be solved. Add money to
            your Quidio balance and ask your question. The question bounty will
            be withdrawn immediately upon asking the question.
          </p>
        </div>

        <div className="md:col-span-2 py-14">
          <img
            src={about_img1}
            alt="people"
            className="object-fill mx-auto my-8"
          />
        </div>

        {/* Section 2 */}
        <div className="md:col-span-2">
          <img src={about_img2} alt="people" className="object-fill w-4/5" />
        </div>

        <div className="md:col-span-3 py-14">
          <div className="bg-blue-dark w-16 h-16 rounded-full inline-block mx-1">
            <div className="w-full h-full">
              <IconContext.Provider
                value={{
                  color: colorConfig['yellow'],
                  className: 'w-10 h-10 block mx-auto mt-3',
                }}
              >
                <MdChat />
              </IconContext.Provider>
            </div>
          </div>
          <h3 className="text-2xl md:text-2xl font-semibold my-3">
            Receive Answers
          </h3>
          <p className="mt-5 ">
            Anyone who sees your question can answer it. Experienced users will
            be incentivized give a great answer to receive the bounty.
          </p>
        </div>

        {/* Section 3 */}
        <div className="md:col-span-3 py-20">
          <img src={quidio_icon} alt="logo" className="h-16 w-16" />
          <h3 className="text-2xl md:text-2xl font-semibold my-3">
            Choose what works
          </h3>
          <p className="mt-5">
            Select the answer that works for you and reject those that don't.
            The selected answerer will receive the bounty.
          </p>
        </div>

        <div className="md:col-span-2 py-10">
          <img
            src={about_img3}
            alt="people"
            className="object-fill mx-auto w-4/5"
          />
        </div>
      </div>
    </section>
  );
}

function ForAnswerers(props: { isExpanded: boolean, isMobile: boolean }) {
  return (
    <section className={`text-black flex flex-col items-center bg-offwhite pb-10 ${(props.isExpanded && !props.isMobile) ? "ml-60" : "ml-24"}`}>
      <div className="grid md:grid-cols-5 ml-24 mr-8 md:mx-20 lg:mx-32">
        {/* Section 1 */}
        <div className="md:col-span-3 pt-24">
          <div className="bg-blue-dark w-16 h-16 rounded-full inline-block mx-1">
            <div className="w-full h-full">
              <IconContext.Provider
                value={{
                  color: colorConfig['yellow'],
                  className: 'w-10 h-10 block mx-auto my-3',
                }}
              >
                <BsQuestionLg />
              </IconContext.Provider>
            </div>
          </div>
          <h3 className="text-2xl md:text-2xl font-semibold my-3">
            Find a question
          </h3>
          <p className="mt-5 w-5/6">
            You have technical knowledge in some area. Use Quidio to find a
            question that is well-suited to your expertise.
          </p>
        </div>

        <div className="md:col-span-2 pt-14">
          <img
            src={about_img4}
            alt="people"
            className="object-fill mx-auto my-8"
          />
        </div>

        {/* Section 2 */}
        <div className="md:col-span-2">
          <img src={about_img5} alt="people" className="object-fill w-4/5" />
        </div>
        <div className="md:col-span-3 py-14">
          <div className="bg-blue-dark w-16 h-16 rounded-full inline-block mx-1">
            <div className="w-full h-full">
              <IconContext.Provider
                value={{
                  color: colorConfig['yellow'],
                  className: 'w-10 h-10 block mx-auto mt-3',
                }}
              >
                <MdChat />
              </IconContext.Provider>
            </div>
          </div>
          <h3 className="text-2xl md:text-2xl font-semibold my-3">
            Submit an answer
          </h3>
          <p className="mt-5 ">
            {' '}
            Carefully read the question and respond in detail to increase your
            chances of having your answer selected to receive the bounty.{' '}
          </p>
        </div>

        {/* Section 3 */}
        <div className="md:col-span-3 py-20">
          <img
            src={quidio_icon}
            alt="people"
            className="h-12 w-12 inline mx-0"
          />
          <h3 className="text-2xl md:text-2xl font-semibold my-3">
            Receive a bounty
          </h3>
          <p className="mt-5">
            If your answer is selected by the Asker, you'll be paid the bounty!
            To withdraw money from Quidio, you must officially register as an
            Answerer.
          </p>
        </div>

        <div className="md:col-span-2 py-10">
          <img
            src={about_img6}
            alt="people"
            className="object-fill mx-auto w-4/5"
          />
        </div>
      </div>
    </section>
  );
}

function HowItWorks() {
  const [isAskers, setAsker] = useState(true);

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const isMobile = window.innerWidth < 768;

  let defState = true;;
  if(sidebarCollapsed === 'true' || isMobile){
    defState = false;
  }
  else if (sidebarCollapsed === 'false') {
    defState = true;
  }
  const [isExpanded, setExpanded] = useState(defState);

  return (
    <>
      <SideBar isExpanded={isExpanded} setExpanded={setExpanded} />
      <TopBar />
      <Header callback={setAsker} isAsker={isAskers} isExpanded={isExpanded} isMobile={isMobile}/>
      {isAskers ? <ForAskers isExpanded={isExpanded} isMobile={isMobile}/> : <ForAnswerers isExpanded={isExpanded} isMobile={isMobile}/>}
    </>
  );
}

export default HowItWorks;
