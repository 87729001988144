import { IconContext } from 'react-icons';
import { useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import ButtonStyled from './ButtonStyled';
import { BsHouseDoor, BsQuestionSquare, BsInfoCircle, BsSearch } from 'react-icons/bs';
import { colorConfig } from '../../config/color.config';

export function SideBar(props) {
  let navigate = useNavigate();

  //const isMobile = window.innerWidth < 768;

  const disactiveclass = "flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
  return (
    <div className={`h-full shadow-md bg-white px-1 fixed ${props.isExpanded ? "w-60": "w-24"} duration-300 z-50`}>
      <div className="pt-2 pb-2 px-4 flex">
        {/* responsive sidebar hamburger button */}
        <button onClick={() => {
          if(props.isExpanded) {
            localStorage.setItem('sidebar-collapsed', true)
            props.setExpanded(false)
            return;
          }
            localStorage.setItem('sidebar-collapsed', false)
            props.setExpanded(true);
          }}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 
                    focus:outline-none focus:ring-2 focus:ring-inset hover:ring-white
                    left-1 top-2 relative">
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
        </button>
        {/* END responsive sidebar hamburger button */}
        <Link to="/feed" className="mt-3 ml-2">
          <div className="flex items-center cursor-pointer">
            <div className="shrink-0">
              <img src="/logo.png" className="w-10" alt="Quidio Logo" />
            </div>
            <div className="grow ml-3">
              <p className={`self-center text-2xl font-semibold whitespace-nowrap ${!props.isExpanded && "hidden"}`}>Quidio</p>
            </div>
          </div>
        </Link>
      </div>
      <div className='flex pt-4 pb-4 px-5'>
        <ButtonStyled
          title={<p className={`${!props.isExpanded && "hidden"} duration-600`}>Ask Question</p>}
          icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/> </svg>}
          onClick={() => {
            navigate('/new-question');
          }}
        />
      </div>
      <div className='gap-x-4 items-center'>
        <ul className="relative">
          <li className="relative">
            <NavLink className={({ isActive }) =>
              (isActive ? "bg-gray-light " : "") + disactiveclass}
              to="/feed" data-mdb-ripple="true" data-mdb-ripple-color="dark">
              <IconContext.Provider value={{ color: colorConfig.black, className: "inline mb-1 mx-1 shrink-0", size: 20 }}>
                <BsHouseDoor />
              </IconContext.Provider>
              <span className={`ml-3 font-medium font-gray ${!props.isExpanded && "hidden"}`}>Home</span>
            </NavLink>
          </li>
          <li className="relative">
            <NavLink className={({ isActive }) =>
              (isActive ? "bg-gray-light " : "") + disactiveclass}
              to="/search?sort=recency" data-mdb-ripple="true" data-mdb-ripple-color="dark">
              <IconContext.Provider value={{ color: colorConfig.black, className: "inline mb-1 mx-1", size: 20 }}>
                <BsSearch />
              </IconContext.Provider>
              <span className={`ml-3 font-medium font-gray ${!props.isExpanded && "hidden"}`}>Questions</span>
            </NavLink>
          </li>
          <li className="relative">
            <NavLink className={({ isActive }) =>
              (isActive ? "bg-gray-light " : "") + disactiveclass}
              to="/FAQ" data-mdb-ripple="true" data-mdb-ripple-color="dark">
              <IconContext.Provider value={{ color: colorConfig.black, className: "inline mb-1 mx-1", size: 20 }}>
                <BsQuestionSquare />
              </IconContext.Provider>
              <span className={`ml-3 font-medium font-gray ${!props.isExpanded && "hidden"}`}>FAQs</span>
            </NavLink>
          </li>
          <li className="relative">
            <NavLink className={({ isActive }) =>
              (isActive ? "bg-gray-light " : "") + disactiveclass}
              to="/how-it-works" data-mdb-ripple="true" data-mdb-ripple-color="dark">
              <IconContext.Provider value={{ color: colorConfig.black, className: "inline mb-1 mx-1", size: 20 }}>
                <BsInfoCircle />
              </IconContext.Provider>
              <span className={`ml-3 font-medium font-gray ${!props.isExpanded && "hidden"}`}>How It Works</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>

  );
}

export default SideBar;