function ButtonBasic(props) {
  return (
    <button
      className={`
      ${props.className} transition whitespace-nowrap border-2 border-gray-700 text-gray-700 font-bold rounded-md py-1.5 px-3
      `}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
}

export default ButtonBasic;
