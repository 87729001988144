//import config from '../config.js';
import { getAuth } from '@firebase/auth';
import devConfig from '../config/config.dev.json';
import prodConfig from '../config/config.prod.json';

const project = process.env.REACT_APP_FIREBASE_PROJECT;
const config = project === 'dev' ? devConfig : prodConfig

export async function getComments(questionId) {
  let response = await fetch(
    config.apiUrl +
    '/api/comments?questionId=' +
    questionId
  );

  let body = await response.json();
  return body;
}

export async function postComment(questionId, comment) {
  let idToken = await getAuth().currentUser.getIdToken();
  let response = await fetch(config.apiUrl + '/api/post-comment', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      idToken,
      questionId,
      comment
    }),
  });

  let body = await response.json();
  return body;
}