function YellowButton(props) {
  if (props.custom === undefined || props.custom !== true) {
    return (
      <button
        className={`
          ${props.className} text-base bg-yellow hover:bg-yellow-light transition whitespace-nowrap text-black font-bold mx-0 rounded-lg py-1.5 px-5 mt-1
          `}
        onClick={props.onClick}
      >
        {props.title}
      </button>
    );
  } else {
    return (
      <button
        className={`
          ${props.className} text-base transition whitespace-nowrap font-bold mx-0 rounded-md py-1.5 px-5 mt-1
          `}
        onClick={props.onClick}
      >
        {props.title}
      </button>
    );
  }
}

export default YellowButton;
