import hljs from 'highlight.js';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaCommentAlt, FaEdit, FaQuestionCircle } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { QuestionType, UserType } from '../../firestoreSchema';
import {
  incrementView,
  removeQuestion,
  renderAndSanitizeBody,
} from '../../lib/questions';
import { getUserPublic } from '../../lib/users';
import '../../styles/components/code_highlight.min.css';
import style from '../../styles/pages/authed/Question.module.css';
import ProfilePhoto from './ProfilePhoto';
import { topicOptions } from './ValidateForm';

interface QuestionPropsType {
  question: QuestionType;
  key: any;
  acceptedAnswer: any;
  isCurrentUserQuestion: any;
}

function Question(props: QuestionPropsType) {
  let [askerInfo, setAskerInfo] = useState<UserType | undefined>();
  let params = useParams();
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const navigate = useNavigate();

  useEffect(() => {
    getUserPublic(props.question.asker).then(setAskerInfo);
  }, [props.question.asker]);

  useEffect(() => {
    const cookies = new Cookies();
    const userCookieName = 'returningVisitor';
    checkUserCookie(userCookieName);

    function checkUserCookie(userCookieName: string) {
      const viewCookie = cookies.get(userCookieName);
      // check if cookie doesn't exist
      // increment view and add cookie
      // check if cookie exists
      // check if question in cookie
      // return null;
      // else
      // incrmeent view and add cookie
      if (typeof viewCookie === 'undefined') {
        // Cookie does not exist
        createUserCookie(userCookieName);
        incrementView(props.question.id);
        console.log('Creating cookie from nothing');
      } else {
        if (viewCookie.includes(props.question.id)) {
          // cookie exists and its for thsi question
          return;
        } else {
          // cookie exists but not for thsi question
          createUserCookie(userCookieName);
          console.log('Creating cookie from something');
          incrementView(props.question.id);
        }
      }
    }

    function createUserCookie(userCookieName: string) {
      const userCookieValue = props.question.id;
      const userCookieDays = 7;
      let expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + userCookieDays);

      const viewCookie = cookies.get(userCookieName);
      let arr = [];
      if (typeof viewCookie === 'undefined') {
        arr = [userCookieValue];
      } else {
        arr = [...viewCookie, userCookieValue];
      }

      cookies.set(userCookieName, arr, { path: '/q' });
    }
  }, [props.question.id]);

  if (!askerInfo) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  let bountyDollars = props.question.bounty / 100;
  let bountyWhole = Math.floor(bountyDollars);
  let cents = Math.floor((bountyDollars - bountyWhole) * 100)
    .toString()
    .padStart(2, '0');

  function toTopicString(questionTopic: string) {
    // Loop through topic options
    for (let i = 0; i < topicOptions.length; i++) {
      // Check if topic is in question
      if (topicOptions[i].value === questionTopic) {
        // Return topic name
        return topicOptions[i].label;
      }
    }
  }

  return (
    <>
      <ReactTooltip />
      <div className="text-2xl font-semibold mt-2 mb-4 flex justify-between">
        <span className="no-underline hover:underline">
          <Link to={`/q/${props.question.id}`}>
            {props.question.title || 'Untitled'}
          </Link>
        </span>
        {props.acceptedAnswer.acceptedExists ? (
          <span
            data-tip="Question bounty: Once the question asker accepts an answer, the accepted answerer will receive the shown bounty."
            className="pl-4 text-5xl text-red cursor-default"
          >
            ${bountyWhole}.{cents}
          </span>
        ) : (
          <span
            data-tip="Question bounty: Once the question asker accepts an answer, the accepted answerer will receive the shown bounty."
            className="pl-4 text-5xl text-black cursor-default"
          >
            ${bountyWhole}.{cents}
          </span>
        )}
      </div>
      <div className="flex align-center">
        <ProfilePhoto size="small" user={askerInfo} edit={false} />
        <div className="ml-2 inline-block mb-4 w-full md:w-1/2">
          <p className="text-lg leading-tight font-bold text-black">
            <Link
              className="hover:underline"
              to={'/user/'.concat(props.question.asker)}
            >
              {askerInfo.name}
            </Link>
          </p>
          <p className="text-base leading-tight text-gray-dark">
            <Link
              className="hover:underline"
              to={'/user/'.concat(props.question.asker)}
            >
              {askerInfo.bio}
            </Link>
          </p>
        </div>
      </div>
      <p
        className={`text-lg my-1 ${style.richText}`}
        dangerouslySetInnerHTML={{
          __html: renderAndSanitizeBody(props.question.bodySource || '', true),
        }}
      />
      {hljs.highlightAll()}

      <div className="flex justify-between">
        <div className="inline my-1">
          <p className="text-base">
            <span className="text-gray-500">Views </span>
            {props.question.viewCount}{' '}
          </p>
          <span className="font-normal">
            <span className="text-gray-500">Asked</span>{' '}
            {props.question.postedTimestamp
              .toDate()
              .toLocaleString([], options)}
          </span>
          {props.question.topic && (
            <p>
              <span className="text-gray-500">in </span>
              {toTopicString(props.question.topic)}
            </p>
          )}
        </div>

        <div className="inline">
          <p>&nbsp;</p>
          {props.isCurrentUserQuestion && (
            <div className="text-lg my-1">
              {props.question.liveAnswersCount +
                props.question.rejectedAnswersCount ===
              0 ? (
                <span
                  data-tip="This removes the question from Quidio. You will receive a refund to your balance immediately."
                  className="mx-4"
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      var clickedOk = confirm(
                        'Are you sure you want to remove this question? This action cannot be undone.'
                      );
                      if (clickedOk) {
                        removeQuestion(params.id).then((result) => {
                          if (result.error) {
                            alert(result.error);
                          } else {
                            alert(
                              'The question has been removed and a refund has been issued to your account.'
                            );
                            navigate('/feed');
                          }
                        });
                      }
                    }}
                    className="hover:underline mr-0.5 inline cursor:pointer"
                  >
                    Remove
                  </a>
                  <Link to="/FAQ" className="hover:underline mr-1">
                    <IconContext.Provider
                      value={{ color: 'black', className: 'inline mb-1 mx-1' }}
                    >
                      <FaQuestionCircle />
                    </IconContext.Provider>
                  </Link>
                </span>
              ) : (
                <span
                  data-tip="You must file an appeal to receive a refund because you have received at least 1 answer"
                  className="mx-4"
                >
                  <a
                    href={
                      'mailto:founders@quidio.co?subject=Question Removal and Refund Request&body=I would like to request the removal and refunding of my Quidio question found at https://quidio.co/q/' +
                      props.question.id
                    }
                    className="hover:underline mr-0.5 inline cursor:pointer"
                  >
                    Appeal for question removal
                  </a>
                  <Link to="/FAQ" className="hover:underline mr-1">
                    <IconContext.Provider
                      value={{ color: 'black', className: 'inline mb-1 mx-1' }}
                    >
                      <FaQuestionCircle />
                    </IconContext.Provider>
                  </Link>
                </span>
              )}
              <Link
                to={'/q/edit/'.concat(props.question.id)}
                className="hover:underline"
              >
                <IconContext.Provider
                  value={{ color: 'black', className: 'inline mr-1 mb-1' }}
                >
                  <FaEdit />
                </IconContext.Provider>
                Edit
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="bg-gray-light rounded-md p-2">
        <span className="inline-block leading-snug my-0.5 btn whitespace-nowrap border-2 border-gray-dark bg-white px-2 rounded-lg mx-1">
          <IconContext.Provider
            value={{ color: 'black', className: 'inline mr-1 mb-1' }}
          >
            <FaCommentAlt />
          </IconContext.Provider>
          <Link to={`/q/${props.question.id}`}>
            {props.question.liveAnswersCount +
              props.question.rejectedAnswersCount || 0}{' '}
            answer
            {props.question.liveAnswersCount +
              props.question.rejectedAnswersCount ===
            1
              ? ''
              : 's'}
          </Link>
        </span>
        {props.question.tags &&
          props.question.tags.map((tag) => (
            <span
              key={uuidv4()}
              className="inline-block leading-snug my-0.5 btn whitespace-nowrap font-gray border-2 border-gray-dark px-2 rounded-lg mx-1 hover:text-white hover:bg-gray-700"
            >
              {tag}
              {/*<Link to={`/search/${tag}`}>{tag}</Link>*/}
            </span>
          ))}
      </div>
      {props.acceptedAnswer.acceptedExists ? (
        <span className="absolute top-1 right-1 inline-block w-6 h-6 transform translate-x-1/2 -translate-y-1/2 bg-red rounded-full"></span>
      ) : (
        <span className="absolute top-1 right-1 inline-block w-6 h-6 transform translate-x-1/2 -translate-y-1/2 bg-blue-dark rounded-full"></span>
      )}
    </>
  );
}

export default Question;
