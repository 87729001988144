import { initializeApp } from 'firebase/app';
import { firebaseDevConfig } from '../config/firebase.dev.js';
import { firebaseProdConfig } from '../config/firebase.prod.js';
import { getAnalytics } from "firebase/analytics";

export function initialize() {
  const firebaseProject = process.env.REACT_APP_FIREBASE_PROJECT;
  if (firebaseProject !== 'dev' && firebaseProject !== 'prod') {
    throw new Error(
      'Must set REACT_APP_FIREBASE_PROJECT to either "dev" or "prod" via environment variable',
    );
  }

  // Initialize Firebase
  const app = initializeApp(firebaseProject === 'dev' ? firebaseDevConfig : firebaseProdConfig);
  getAnalytics(app);
}
