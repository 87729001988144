import 'modern-normalize';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Balance from './components/pages/authed/Balance';
import Footer from './components/components/Footer';
import PrivateRoute from './components/components/PrivateRoute';
import EditQuestionPage from './components/pages/authed/EditQuestion';
import NewQuestionPage from './components/pages/authed/NewQuestion';
import UserEditPage from './components/pages/authed/UserEdit';
import HowItWorks from './components/pages/unauthed/HowItWorks';
import FeedPage from './components/pages/unauthed/Feed';
import HomePage from './components/pages/unauthed/Landing';
import UnauthedLogInPage from './components/pages/unauthed/LogIn';
import NotFoundPage from './components/pages/unauthed/NotFoundPage';
import QuestionPage from './components/pages/unauthed/QuestionPage';
import QuidioHow from './components/pages/unauthed/QuidioHow';
import SearchPage from './components/pages/unauthed/Search';
import UnauthedSignUpPage from './components/pages/unauthed/SignUp';
import UserPage from './components/pages/unauthed/User';
import ComingSoon from './components/pages/unauthed/ComingSoon';
import Team from './components/pages/unauthed/Team';
import { initialize } from './lib/firebase';

initialize();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/user/edit"
          element={
            <PrivateRoute>
              <UserEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/balance"
          element={
            <PrivateRoute>
              <Balance />
            </PrivateRoute>
          }
        />
        <Route
          path="/q/edit/:id"
          element={
            <PrivateRoute>
              <EditQuestionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-question"
          element={
            <PrivateRoute>
              <NewQuestionPage />
            </PrivateRoute>
          }
        />

        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/team" element={<Team />} />
        <Route path="/FAQ" element={<QuidioHow />} />
        <Route path="/sign-up" element={<UnauthedSignUpPage />} />
        <Route path="/log-in" element={<UnauthedLogInPage />} />
        <Route path="/q/:id" element={<QuestionPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route
          path="/user/:uid/questions"
          element={<UserPage filter="questions" />}
        />
        <Route
          path="/user/:uid/answers"
          element={<UserPage filter="answers" />}
        />
        <Route path="/user/:uid" element={<UserPage filter="questions" />} />
        <Route path="/feed" element={<FeedPage />} />
        <Route path="/:partner" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
      <Routes>
        <Route path="/sign-up"></Route>
        <Route path="/log-in"></Route>
        <Route path="*" element={<Footer />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
